import React from "react";
import CommonExternalPage from "./CommonExternalPage";

const content = [
 
  {
    title: "Physical Address",
    body: "Ipswich, QLD - Australia"
  },
  {
    title: "Email",
    body: "contact@grow2.com.au"
  },
  {
    title: "Book a meeting with founder",
    body: "https://calendly.com/join-now/marc"
  },
  {
    title: "",
    body: "Thank you for considering uTRADIE as your platform of choice for all things trade-related. ~~As a fellow tradie and the creator of uTRADIE, I embarked on this journey with a simple goal in mind: to empower everyone in the trade industries. It's important to me that you know uTRADIE is entirely self-funded, which means that while there may be bugs or aspects you love and don't love, I am dedicated to making it the best it can be.~~Your feedback is invaluable to me. Whether you've found something you love or something you think could be improved, I encourage you to let me know by filling out the information and adding screenshots or screen recordings to help. Together, we can shape uTRADIE into a platform that meets the needs of tradespeople everywhere. I am committed to this journey, and I invite you to join me in building a brighter future for our industry and the next generation of tradies.~~Let's work together to make uTRADIE the best it can be.~~Cheers,~~Marc"
  },
];

const ContactUsPage = () => {
  return <CommonExternalPage title="Contact Us" content={content} />
};

export default ContactUsPage;
