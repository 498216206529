// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

// import {
//   Box,
//   Text,
//   Image,
//   Divider,
//   Card,
//   CardBody,
//   Select,
//   Progress,
//   Circle,
//   useToast,
//   InputGroup,
//   Input,
//   InputRightElement,
//   IconButton,
//   Checkbox,
//   Link,
//   Accordion,
//   AccordionItem,
//   AccordionButton,
//   AccordionIcon,
//   AccordionPanel,
// } from "@chakra-ui/react";

// import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
// import G2Button14 from "../Common/G2Button14";
// import {
//   getContentList,
//   searchContentList,
//   getMyContentInfo,
//   getMyContent,
//   getSomeContent,
//   makeContent,
//   addToBusket,
//   removeToBusket,
//   getBusket,
//   getCategory,
//   addTreeItem,
//   removeTreeItem,
//   removeTreeNode,
//   incViewCnt,
// } from "../../Store/Actions/marketplaceAction";


// import "../../App.css";

// import { useStateContext } from "../../ContextProvider/Context";
// import { getToken, verifyToken } from "../../utils";
// import {
//   getGoalList,
//   addGoal,
//   updateGoal,
//   deleteGoal,
// } from "../../Store/Actions/goalAction";
// import g2Toast from "../../Custom/g2Toast";
// import isEmptyObject from "../../Custom/isEmptyObject";
// import isEmptyValue from "../../Custom/isEmptyValue";

// import G2ConfirmAlert from "../Common/G2ConfirmAlert";


// // import AddModal from "./AddModal";

// import G2Button10 from "../Common/G2Button10";
// import G2Button20 from "../Common/G2Button20";

// import avatar1 from "../../assets/dashboard/avatar1.png";
// import avatar2 from "../../assets/dashboard/avatar2.png";
// import avatar3 from "../../assets/dashboard/avatar3.png";
// import avatar4 from "../../assets/dashboard/avatar4.png";
// import avatar5 from "../../assets/dashboard/avatar5.png";

// const mentors = [
//   {
//     img: avatar1,
//     title: "Jon Arthur",
//     subtitle: "1st",
//   },
//   {
//     img: avatar2,
//     title: "Somi Thakar",
//     subtitle: "2nd",
//   },
//   {
//     img: avatar3,
//     title: "Krishiv Mehta",
//     subtitle: "3rd",
//   },
// ];

// const champions = [
//   {
//     img: avatar4,
//     title: "Ram Jani",
//     subtitle: "1st",
//   },
//   {
//     img: avatar5,
//     title: "Priyanka Seth",
//     subtitle: "2nd",
//   },
//   {
//     img: avatar2,
//     title: "Somi Thakar",
//     subtitle: "3rd",
//   },
// ];

// const DgitalPassportForm = (props) => {
//   const { screenSize, boxW1, boxW2 } = props;

//   const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
//     useStateContext();

//   const navigate = useNavigate();

//   const dispatch = useDispatch();

//   const toast = useToast();

//   const schedules = useSelector((state) => state.Goal.goalList);

//   const [isGoalDopen, setIsGoalDopen] = useState(false);
//   const [isEditable, setIsEditable] = useState(false);
//   const [sort, setSort] = useState("Featured");
//   const [currentPage, setCurrentPage] = useState(1);

//   const token = getToken();

//   const user = useSelector((state) => state.Auth.user);
//   const contentList = useSelector((state) => state.Marketplace.contentList);
//   const busket = useSelector((state) => state.Marketplace.busket);
//   const category = useSelector((state) => state.Marketplace.category);
//   const posted = useSelector((state) => state.Marketplace.posted);
//   const draft = useSelector((state) => state.Marketplace.draft);

//   const handlePreviousClick = () => {
//     navigate("/academy/digital");
//   };

//   const handleNextClick = () => {
//     navigate("/academy/job");
//   };
//   const handleAllClick = () => {
//     dispatch(getContentList(toast, sort, currentPage));
//   };
//   const handleGetMyContentInfo = () => {
//     dispatch(getMyContentInfo(toast));
//   };

//   const handleMyNodeClick = (cond) => {
//     if (cond == 1) {
//       dispatch(getMyContent(toast, "Posted"));
//     }
//     if (cond == 2) {
//       dispatch(getMyContent(toast, "Draft"));
//     }
//   };
//   const handleNodeClick = (itemId, nodeId) => {
//     dispatch(getSomeContent(toast, sort, itemId, nodeId));
//   };


//   return (
//     <Box
//       w="100%"
//       h="100%"
//       style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
//     >
//       <Box
//         w="100%"
//         h="80px"
//         bg={G2_color_2}
//         className="flex-box centering-h px-[30px] shadow"
//       >
//         <Text
//           fontSize="20px"
//           className="font-semibold mr-[10px] text-[var(--orange)]"
//         >
//           Leader Boards
//         </Text>
//       </Box>
//       <Box w="100%" px="30px" py="30px" className="flex-box">
//         <Box w={boxW1} className="pr-[15px]">
//           <Box w="100%" className="flex-box" display="flex" alignItems="flex-start" gap="10px">
//             <Box w="100%" h="30px" fontSize="20px" className="pl-[15px] flex-box">
//               Popular categories
//             </Box>
//             <Box w="50%" className="px-[10px]">
//               <G2Button14
//                 widthVal="100%"
//                 heightVal="35px"
//                 text="View All"
//                 onClick={handleAllClick}
//               />
//             </Box>
//             <Box w="50%">
//               <Accordion className="p-[10px] space-y-2" allowMultiple>
//                 {verifyToken() && (
//                   <AccordionItem>
//                     <h2>
//                       <AccordionButton
//                         _expanded={{ bg: "#E0F1FF", color: "black" }}
//                         onClick={handleGetMyContentInfo}
//                       >
//                         <Box flex="1" textAlign="left" className="text-[var(--orange)] font-semibold">
//                           My content
//                         </Box>
//                         <AccordionIcon />
//                       </AccordionButton>
//                     </h2>
//                     <AccordionPanel pb={4} bg={G2_color_2} color={G2_color_3}>
//                       <Box
//                         className="flex-box cursor-pointer py-[5px] normal-hover"
//                         onClick={() => handleMyNodeClick(1)}
//                       >
//                         <Box className="market-accordin-box1">
//                           <Text fontSize="16px">Posted</Text>
//                         </Box>
//                         <Box className="market-accordin-box2 centering-w">
//                           <Text fontSize="16px">{posted}</Text>
//                         </Box>
//                       </Box>
//                       <Box
//                         className="flex-box cursor-pointer py-[5px] normal-hover"
//                         onClick={() => handleMyNodeClick(2)}
//                       >
//                         <Box className="market-accordin-box1">
//                           <Text fontSize="16px">Draft</Text>
//                         </Box>
//                         <Box className="market-accordin-box2 centering-w">
//                           <Text fontSize="16px">{draft}</Text>
//                         </Box>
//                       </Box>
//                     </AccordionPanel>
//                   </AccordionItem>
//                 )}
//                 {category?.map((element, i) => (
//                   <AccordionItem key={i}>
//                     <h2>
//                       <AccordionButton
//                         _expanded={{ bg: "#E0F1FF", color: "black" }}
//                       >
//                         <Box flex="1" textAlign="left">
//                           {element.item}
//                         </Box>
//                         <AccordionIcon />
//                       </AccordionButton>
//                     </h2>
//                     <AccordionPanel pb={4} bg={G2_color_2} color={G2_color_3}>
//                       {element.nodes?.map((val, i) => (
//                         <Box
//                           key={i}
//                           className="flex-box cursor-pointer py-[5px] normal-hover"
//                           onClick={() => handleNodeClick(element._id, val.id)}
//                         >
//                           <Box className="market-accordin-box1">
//                             <Text fontSize="16px">{val.text}</Text>
//                           </Box>
//                           <Box className="market-accordin-box2 centering-w">
//                             <Text fontSize="16px">{val.size}</Text>
//                           </Box>
//                         </Box>
//                       ))}
//                     </AccordionPanel>
//                   </AccordionItem>
//                 ))}
//               </Accordion>
//             </Box>
            
//           </Box>
//         </Box>
//         <Box w={boxW1} className="pr-[15px]" style={{marginLeft:"-88px" }}>
//           <Box w="100%" className="flex-box">
//             <Box className="academy-leader-box1 centering relative">
//               <Text fontSize="24px" className="font-semibold">
//                 25th
//               </Text>
//               <Text fontSize="14px" className="absolute top-[0px] left-[5px]">
//                 {user.name} is:
//               </Text>
//             </Box>
//             <Box className="academy-leader-box2">
//               <Text
//                 w="100%"
//                 fontSize="16px"
//                 className="font-semibold"
//                 style={{ paddingLeft: "calc(50% - 45px)" }}
//               >
//                 Top Mentors
//               </Text>
//               <Box w="100%" className="flex px-[10px]">
//                 {mentors.map((val, i) => (
//                   <Box
//                     key={i}
//                     w="125px"
//                     className="p-[10px] flex-box centering relative"
//                   >
//                     <Image w="50px" src={val.img} />
//                     <Text w="100%" fontSize="14px" className="text-center">
//                       {val.title}
//                     </Text>
//                     <Text
//                       w="100%"
//                       fontSize="12px"
//                       className="absolute top-[5px] left-[10px]"
//                     >
//                       {val.subtitle}
//                     </Text>
//                   </Box>
//                 ))}
//               </Box>
//             </Box>
//           </Box>
//           <Box w="100%" className="flex-box mt-[20px]">
//             <Box className="academy-leader-box1 centering relative">
//               <Text fontSize="24px" className="font-semibold">
//                 3rd
//               </Text>
//               <Text fontSize="14px" className="absolute top-[0px] left-[5px]">
//                 {user.name} is:
//               </Text>
//             </Box>
//             <Box className="academy-leader-box2">
//               <Text
//                 w="100%"
//                 fontSize="16px"
//                 className="font-semibold"
//                 style={{ paddingLeft: "calc(50% - 70px)" }}
//               >
//                 Creator Champions
//               </Text>
//               <Box w="100%" className="flex px-[10px]">
//                 {champions.map((val, i) => (
//                   <Box
//                     key={i}
//                     w="125px"
//                     className="p-[10px] flex-box centering relative"
//                   >
//                     <Image w="50px" src={val.img} />
//                     <Text w="100%" fontSize="14px" className="text-center">
//                       {val.title}
//                     </Text>
//                     <Text
//                       w="100%"
//                       fontSize="12px"
//                       className="absolute top-[5px] left-[10px]"
//                     >
//                       {val.subtitle}
//                     </Text>
//                   </Box>
//                 ))}
//               </Box>
//             </Box>
//           </Box>
//           <Box w="100%" className="flex-box mt-[20px]">
//             <Box className="academy-leader-box1 centering relative">
//               <Text fontSize="24px" className="font-semibold">
//                 N/A
//               </Text>
//               <Text fontSize="14px" className="absolute top-[0px] left-[5px]">
//                 {user.name} is:
//               </Text>
//               <Text
//                 w="100%"
//                 fontSize="12px"
//                 className="text-center absolute bottom-[10px]"
//               >
//                 Not a apprentice
//               </Text>
//             </Box>
//             <Box className="academy-leader-box2">
//               <Text
//                 w="100%"
//                 fontSize="16px"
//                 className="font-semibold"
//                 style={{ paddingLeft: "calc(50% - 55px)" }}
//               >
//                 Top Apprentices
//               </Text>
//             </Box>
//           </Box>
//         </Box>
//         <Box w={boxW1} className="pl-[15px]" style={{marginLeft:"600px" , marginTop:"50px"}}>
//           <Text w="100%" fontSize="18px" className="font-semibold text-center">
//             Competitions
//           </Text>
//           <Box w="100%" className="mt-[10px]">
//             <Box
//               w="100%"
//               bg="#d86655"
//               className={`border ${G2_theme_mode == "light" ? "border-gray-600" : "border-gray-300"
//                 } border-[3px]`}
//             >
//               <Text
//                 w="100%"
//                 fontSize="16px"
//                 className="px-[10px] py-[5px] font-semibold text-center"
//               >
//                 SNAP ON TOOLS
//               </Text>
//             </Box>
//             <Box
//               w="100%"
//               className={`p-[10px] border-b-[3px] border-r-[3px] border-l-[3px] ${G2_theme_mode == "light" ? "border-gray-600" : "border-gray-300"
//                 }`}
//             >
//               <Link>Best Use Case Challenge</Link>
//               <Text fontSize="14px" className="mt-[10px]">
//                 Description: Users submit creative videos or photos showcasing
//                 innovative ways they use the product in their trade.
//               </Text>
//               <Text fontSize="14px" className="mt-[10px]">
//                 Rewards: The winner receives the latest product model or a
//                 premium toolset from the company.
//               </Text>
//               <Box w="100%" className="mt-[10px] flex justify-end">
//                 <G2Button20
//                   widthVal="110px"
//                   heightVal="35px"
//                   text="ENTER NOW"
//                 />
//               </Box>
//             </Box>
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default DgitalPassportForm;

import React from "react";
import Csoon from "../../assets/landing_page/csoon.png";

const DgitalPassportForm = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Full viewport height
        background: "#fff", // Ensure white background in the area
      }}
    >
      <img
        src={Csoon}
        alt="Coming Soon"
        style={{
          maxWidth: "100%", // Prevent image from overflowing
          maxHeight: "90%", // Maintain aspect ratio
          objectFit: "contain", // Ensure the image fits within bounds
          borderRadius:"100%"
        }}
      />
    </div>
  );
};

export default DgitalPassportForm;

