import React from "react";
import {
  Box,
  Image,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from "@chakra-ui/react";

const SocialComponent = ({ social }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <a href={social.link} target="_blank" rel="noopener noreferrer">
        <Box position="relative" display="inline-block">
          <Image
            src={social.icon}
            boxSize="40px"
            borderRadius="full"
            alt={social.name}
          />
        </Box>
        </a>
    </>
  );
};

export default SocialComponent;
