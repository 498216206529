import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	Box,
	Text,
	Image,
	Card,
	CardBody,
	Input,
	Textarea,
	IconButton,
	AspectRatio,
	useToast,
	Spinner,
	Select,
	Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
	useDisclosure,
	Stack,
	Checkbox,
	CardHeader,
	HStack,
	InputGroup, 
	InputRightElement,

} from "@chakra-ui/react";

import { CloseIcon, DeleteIcon } from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { addContent, getCategory, makePreview, updateContent } from "../../Store/Actions/marketplaceAction";
import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2Button12 from "../Common/G2Button12";
import G2Button13 from "../Common/G2Button13";

import upload1 from "../../assets/marketplace/upload1.png";
import upload2 from "../../assets/marketplace/upload2.png";
import upload3 from "../../assets/marketplace/upload3.png";
import DynamicFileViewer from "../DynamicFileViewer";
import { isFile } from "../../helpers/general";
import _ from "lodash";
import ViewForm from "./ViewForm";
import { getTotalList } from "../../Store/Actions/createAction";

const UploadArea = ({ model, path, set, icon, title, className, accept = ".*", alt = "upload" }) => {
	const fileInputRef = useRef(null);

	const value = useMemo(() => {
		return _.get(model, path) ?? null;
	}, [model, path]);

	if (value == null) {
		return <Box className="flex-box">
			<Box w="100%" className="centering-w">
				<Box className={className}>
					<Image boxSize="100%" src={icon} onClick={() => fileInputRef.current.click()} alt={alt} />
					<Input type="file" ref={fileInputRef} accept={accept} onChange={(e) => set(path, e.target.files[0])} style={{ display: "none" }} />
				</Box>
			</Box>
			<Box w="100%" className="centering-w">
				<Text fontSize="14px">{title}</Text>
			</Box>
		</Box>
	}

	return <Box w="100%" h="100%" className="relative">
		<AspectRatio w="100%" h="100%" className="centering" ratio={16 / 9} maxW="400px">
			<DynamicFileViewer file={value} />
		</AspectRatio >
		<IconButton position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" variant="outline" isRound={true} color="white" fontSize="15px" icon={<DeleteIcon />} onClick={() => set(model, path, null)} />
	</Box >
}


const RegisterForm = ({ content = null }) => {
	const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();
	const user = useSelector((state) => state.Auth.user);
	const dispatch = useDispatch();

	const toast = useToast();

	const category = useSelector((state) => state.Marketplace.category);
	const contentList = useSelector((state) => state.Create.contentList);

	useEffect(() => {
		dispatch(getCategory(toast, true));
		dispatch(getTotalList(toast));
	}, []);

	const [wait, setWait] = useState(false);

	const [data, setData] = useState({
		contentName: "",
		producedBy: "",
		creatorDescription: "",
		creatorEmail: "",
		overview: "",
		videodescription: "",
		units: "",
		description: "",
		creatorCost: "",
		memberCost: "",
		cover: null,
		logo: null,
		video: null,
		posted: false,
		categoryTitle: "",
		nodeTitle: "",
		itemId: "",
		nodeId: "",
		content: [],
	});
	/*
			contentName: "a",
		producedBy: "b",
		creatorDescription: "c",
		creatorEmail: "d",
		overview: "e",
		videodescription: "f",
		units: "g",
		description: "h",
		creatorCost: "1",
		memberCost: "2",
		cover: null,
		logo: null,
		video: null,
		posted: false,
		categoryTitle: "",
		nodeTitle: "",
		itemId: "",
		nodeId: "",
		content: [],

	*/

	useEffect(() => {
		if (content != null) {
			setData(content);
		}
	}, []);

	const update = useCallback((path, value) => {
		let temp = _.cloneDeep(data);
		temp = _.set(temp, path, value);
		setData(temp);
	}, [data, setData]);


	useEffect(() => {
		if (content == null && data?.categoryTitle == "" && category?.length > 0) {
			let c = category.filter(r => r.userSelectable == 1)[0];
			let n = c.nodes.filter(r => r.userSelectable == 1)[0];

			let temp = _.cloneDeep(data);
			temp.categoryTitle = c.item;
			temp.nodeTitle = n.text;
			temp.itemId = c._id;
			temp.nodeId = n.id;
			setData(temp);
		}
	}, [data, content, category]);

	const handleSaveContent = useCallback((posted = false) => {
		let temp = _.cloneDeep(data);
		temp.posted = posted;

		// if (isEmptyObject(temp)) {
		// 	g2Toast({ toast, status: "error", title: "All input fields must be filled out!", });
		// 	return;
		// }

		if (
			parseFloat(temp.memberCost) + "" != temp.memberCost ||
			parseFloat(temp.creatorCost) + "" != temp.creatorCost) {
			g2Toast({ toast, status: "error", title: "Costs must be numbers", });
			return;
		}

		if (temp.memberCost < 0 || temp.creatorCost < 0) {
			g2Toast({ toast, status: "error", title: "Costs must be greater than or equal to Zero", });
			return;
		}

		const formData = new FormData();

		[
			'cover',
			'logo',
			'video'
		].forEach((field, i) => {
			if (isFile(temp[field])) {
				formData.append(field, temp[field]);
				_.unset(temp, field);
			}
		});

		console.log(temp);
		formData.append("data", JSON.stringify(temp));

		setWait(true);
		if (content == null) {
			dispatch(addContent(toast, navigate, formData, setWait));
		} else {
			dispatch(updateContent(toast, navigate, content._id, formData, setWait));
		}
	}, [data, content]);

	const categoryChange = useCallback((value) => {
		const targetCategory = category.find(r => r._id + "" == value + "");
		const targetNode = targetCategory?.nodes[0];
		let temp = _.cloneDeep(data);
		temp.categoryTitle = targetCategory.item;
		temp.nodeTitle = targetNode.text;
		temp.itemId = targetCategory._id;
		temp.nodeId = targetNode.id;
		setData(temp);
	}, [data, category]);

	const nodeChange = useCallback((value) => {
		const targetCategory = category.find(r => r._id + "" == data.itemId + "");
		const targetNode = targetCategory.nodes.find(r => r.id + "" == value + "");
		let temp = _.cloneDeep(data);
		temp.nodeTitle = targetNode.text;
		temp.nodeId = targetNode.id;
		setData(temp);
	}, [data, category]);

	const toggleContent = useCallback((contentId) => {
		let temp = _.cloneDeep(data);
		if (temp.content.indexOf(contentId) !== -1) {
			temp.content = temp.content.filter(r => r != contentId);
		} else {
			temp.content.push(contentId);
		}
		setData(temp);
	}, [data]);
	return (
		<Box className="market-main-padding" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
			{wait ? (
				<Box w="100%" className="centering-w mt-[200px]">
					<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="lg" />
				</Box>
			) : (
				<Box w="100%">
					<Box>
						<Text className="font-semibold ml-2.5 mb-2.5" fontSize="20px">
							{content != null ? "Edit" : "Create"} Content
						</Text>
					</Box>
					<Box w="100%" className="flex-box">
						<Box className="market-reg-box1">
							{
								//JSON.stringify(data)
							}
							<Card w="100%" h="100%" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
								<CardBody>
									<Box w="100%" h="100%" className="flex-box">
										<Box className="market-reg-box3">
											<Box className="flex-box market-reg-upload1-box centering">
												<UploadArea icon={upload1} model={data} path="cover" set={update} title="Upload Cover" className="market-reg-upload1 aspect-w-4" accept=".png, .jpg, .jpeg" alt="cover" />
											</Box>
										</Box>
										<Box className="market-reg-box4 flex-box">
											<Input w="100%" placeholder="Name of content" size="sm" value={data.contentName} onChange={(e) => update("contentName", e.target.value)} />
											<Input w="100%" className="mt-2.5" placeholder="Produced by" size="sm" value={data.producedBy} onChange={(e) => update("producedBy", e.target.value)} />
											<Textarea w="100%" className="mt-2.5" placeholder="Creator description" size="sm" rows={3} value={data.creatorDescription} onChange={(e) => update("creatorDescription", e.target.value)} />
											<Input w="100%" className="mt-2.5" placeholder="Creator email" size="sm" value={data.creatorEmail} onChange={(e) => update("creatorEmail", e.target.value)} />
										</Box>
									</Box>
								</CardBody>
							</Card>
						</Box>
						<Box className="market-reg-box2">
							<Card w="100%" h="100%" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
								<CardBody>
									<Box w="100%" h="100%" className="centering">
										<Box className="flex-box">
											<Box w="100%" className="centering-w">
												<Box className="market-reg-upload2-box centering">
													<UploadArea icon={upload2} model={data} path="logo" set={update} title="Upload Logo" className="market-reg-upload2" accept=".png, .jpg, .jpeg" alt="upload" />
												</Box>
											</Box>
											<Box w="100%" className="centering-w">
												<Text fontSize="14px" className="mt-2.5">
													{user?.name}
												</Text>
											</Box>
										</Box>
									</Box>
								</CardBody>
							</Card>
						</Box>
					</Box>
					<Box w="100%" className="flex-box p-[10px]">
						<Card w="100%" h="100%" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
							<CardBody>
								<Box w="100%" h="100%" className="flex-box">
									<Box className="market-reg-box5">
										<Box className="market-reg-upload3-box centering">
											<UploadArea icon={upload3} model={data} path="video" set={update} title="Upload Video" className="market-reg-upload3" accept=".mp4, .mpg, .avi, .3gp" alt="upload" />
										</Box>
									</Box>
									<Box className="market-reg-box6 flex-box">
										<Box w="100%">
											<Text fontSize="14px" className="mb-1">
												Overview
											</Text>
											<Textarea w="100%" size="sm" rows={5} value={data.overview} onChange={(e) => update("overview", e.target.value)} />
										</Box>
										<Box w="100%" className="mt-2.5">
											<Text fontSize="14px" className="mb-1">
												Video description
											</Text>
											<Textarea w="100%" size="sm" rows={3} value={data.videodescription} onChange={(e) => update("videodescription", e.target.value)} />
										</Box>
									</Box>
								</Box>
							</CardBody>
						</Card>
					</Box>
					<Box w="100%" className="flex-box">
						<Box className="market-reg-box7">
							<Card w="100%" h="100%" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
								<CardBody>
									<Box w="100%" className="flex-box">
										<Box className="market-reg-box9">
											<Text fontSize="14px" className="mb-1">
												Units
											</Text>
										</Box>
										<Box className="market-reg-box10">
											<Input w="100%" size="sm" value={data.units} onChange={(e) => update("units", e.target.value)} />
										</Box>
									</Box>
									<Box w="100%" className="flex-box mt-2.5">
										<Box className="market-reg-box9">
											<Text fontSize="14px" className="mb-1">
												Description
											</Text>
										</Box>
										<Box className="market-reg-box10">
											<Textarea w="100%" size="sm" rows={3} value={data.description} onChange={(e) => update("description", e.target.value)} />
										</Box>
									</Box>
								</CardBody>
							</Card>
						</Box>
						<Box className="market-reg-box8">
							<Card w="100%" h="100%" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
								<CardBody>
									<Box w="100%" className="flex-box">
										<Box className="market-reg-box11">
											<Text fontSize="14px" className="mb-1">
												Business Cost
											</Text>
										</Box>
											<Box>
												<InputGroup size="sm" style={{width:"480px"}}>
													<Input
														value={data.creatorCost}
														onChange={(e) => update("creatorCost", e.target.value)}
														type="number"
														placeholder="Enter cost"
														pr="4rem" // Padding to make space for the "$"
													/>
													<InputRightElement>
														<Text color="gray.500">$</Text>
													</InputRightElement>
</InputGroup>
											</Box>
									</Box>
									<Box w="100%" className="flex-box mt-2.5">
										<Box className="market-reg-box11">
											<Text fontSize="14px" className="mb-1">
												Individual Cost
											</Text>
										</Box>
										<Box className="market-reg-box12">
											<Input w="100%" size="sm" value={data.memberCost} onChange={(e) => update("memberCost", e.target.value)} />
										</Box>
									</Box>
								</CardBody>
							</Card>
						</Box>
					</Box>
					<Box w="100%" className="flex-box">
						<Box className="market-reg-box7">
							<Card w="100%" h="100%" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
								<CardBody>
									<Box w="100%" className="flex-box">
										<Box className="market-reg-box13">
											<Text fontSize="14px" className="mb-1">
												Category
											</Text>
										</Box>
										{
											//JSON.stringify(category)
										}
										<Box className="market-reg-box14">
											<Select width="100%" height="35px" className="text-sm" value={data.itemId} onChange={(e) => categoryChange(e.target.value)} style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
												{category?.filter(r => r.userSelectable == 1)?.map((val, i) => (
													<option key={i} value={val._id} style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
														{val.item}
													</option>
												))}
											</Select>
										</Box>
									</Box>
									<Box w="100%" className="flex-box mt-2.5">
										<Box className="market-reg-box13">
											<Text fontSize="14px" className="mb-1">
												Sub-category
											</Text>
										</Box>
										<Box className="market-reg-box14">
											<Select width="100%" height="35px" className="text-sm" value={data.nodeId} onChange={(e) => nodeChange(e.target.value)} style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
												{category?.find((r) => r._id == data.itemId)?.nodes?.filter(r => r.userSelectable == 1)?.map((val, i) => (
													<option key={i} value={val.id} style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
														{val.text}
													</option>
												))}
											</Select>
										</Box>
									</Box>
								</CardBody>
							</Card>
						</Box>


						<Box className="market-reg-box8">
							<Card w="100%" h="100%" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
								<CardHeader className="!pb-1">Content</CardHeader>
								<CardBody className="!pt-0">

									<Stack pl={6} mt={1} spacing={1}>
										{contentList?.map(r => <Checkbox key={r._id} isChecked={data?.content?.indexOf(r._id) >= 0} onChange={(e) => toggleContent(r._id)}>{r.title}</Checkbox>)}
									</Stack>
									{
										//JSON.stringify(contentList)
									}
								</CardBody>
							</Card>
						</Box>
					</Box>

					<Box w="100%" className="flex-box">
						<Box display="flex" justifyContent="flex-end" alignItems="end" w="100%">
							<Box className="text-left market-preview-btn mr-[20px]">
								<G2Button13 widthVal="150px" heightVal="40px" text="Preview" icon="preview" onClick={onOpen} />
							</Box>
							<Box className="text-right mr-[20px]">
								<G2Button13 widthVal="200px" heightVal="40px" text={content == null ? "Save to draft" : "Update"} icon="save" onClick={() => handleSaveContent()} />
							</Box>
							{content != null && <Box className="text-right">
								<G2Button13 widthVal="200px" heightVal="40px" text={"Add to Marketplace"} icon="save" onClick={() => handleSaveContent(true)} />
							</Box>}
						</Box>
					</Box>
				</Box>
			)
			}
			<Modal size="full" onClose={onClose} isOpen={isOpen} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Preview</ModalHeader>
					<ModalCloseButton />
					<ModalBody p="0">
						<HStack w="100%" justifyContent="center">
							<Box w="1024px">
								<ViewForm content={data} isPreview={true} />
							</Box>
						</HStack>
					</ModalBody>
				</ModalContent>
			</Modal >
		</Box >
	);
};

export default RegisterForm;
