import React, { useState, useEffect, useMemo, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Container,
  HStack,
  IconButton,
  Image,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import styled from "styled-components";

import "../App.css";

import { useStateContext } from "../ContextProvider/Context";
import { goToLogin } from "../Store/Actions/authAction";
import { getToken, verifyToken } from "../utils";

import Navbar from "../Components/Navbar/Navbar";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getSystemContent } from "../Store/Actions/otherAction";
import { useSelector } from "react-redux";
import { getServerUrl } from "../helpers/location";
import background from "../assets/landing_page/background.png";
import UTradieText from "../Components/Common/UTradieText";
import twitter from "../assets/social/twitter.png";
import facebook from "../assets/social/facebook.png";
import instagram from "../assets/social/instagram.png";
import tiktok from "../assets/social/tiktok.png";
import spotify from "../assets/social/spotify.png";
import youtube from "../assets/social/youtube.png";
import dashboardImage from "../assets/landing_page/image12.png";
import UTradie_Logo from "../assets/landing_page/UtradieLogo.png";
import G2Button27 from "../Components/Common/G2Button27";
import PricingTiers from "../Components/Common/PricingTier";
import { useLocation } from "react-router-dom";
import i1 from "../assets/social/admin.png";
import i2 from "../assets/social/aussie.png";
import i3 from "../assets/social/grow.png"
import img1 from "../assets/social/img1.png";
import img2 from "../assets/social/img2.png";
import img3 from "../assets/social/img3.png"
import IMG1 from "../assets/landing_page/1.png"
import IMG2 from "../assets/landing_page/2.png"
import IMG3 from "../assets/landing_page/3.png"
import IMG4 from "../assets/landing_page/4.png"
import IMG5 from "../assets/landing_page/5.png"
import IMG6 from "../assets/landing_page/6.png"

const StyledText = styled.div`
  font-family: "Trebuchet MS";
`;

const Carousel = ({ items, children, settings }) => {
  const sortedItems = useMemo(() => {
    return items.sort((a, b) => a.index - b.index);
  }, [items]);

  return (
    <>
      <div className="slider-container">
        <Slider {...settings}>
          {sortedItems.map((item, index) => {
            return (
              <Box key={index} w="100%">
                {children(item, index)}
              </Box>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

const LandingPage = () => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const location = useLocation();
  const pricingTiersRef = useRef(null);

  // const social = [twitter, facebook, instagram, tiktok, spotify, youtube];

  const social = [
    { src: facebook, url: "https://www.facebook.com/join.UTRADIE/" },
    { src: instagram, url: "https://www.instagram.com/join.utradie/" },
    { src: twitter, url: "https://twitter.com/utradie" },
    // { src: LinkedIn, url: "https://www.linkedin.com/company/grow2company" },
    { src: youtube, url: "https://www.youtube.com/@utradie" },
    { src: spotify, url: "https://www.reddit.com/user/UTRADIE/" },
    { src: tiktok, url: "https://www.tiktok.com/@utradie" },
  ];

  const systemContent = useSelector((state) => state.Onboard.systemContent);
  const navigate = useNavigate();

  const token = getToken();

  const dispatch = useDispatch();

  const [screenSize, setScreenSize] = useState(1920);

  const isLargeScreen = screenSize > 1024;

  useEffect(() => {
    // Fetch system content
    dispatch(getSystemContent());

    // Check location state to determine if scroll action is needed
    if (location.state && location.state.scrollTo === "pricingTiers") {
      if (pricingTiersRef.current) {
        // Add a 1-second delay before scrolling
        const scrollTimeout = setTimeout(() => {
          pricingTiersRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }, 100);

        // Cleanup timeout on component unmount or on dependency change
        return () => clearTimeout(scrollTimeout);
      }
    }
  }, [dispatch, location.state]);

  useEffect(() => {
    setScreenSize(window.innerWidth);
    window.addEventListener("resize", setScreenSize);
    dispatch(getSystemContent());
  }, [window.innerWidth]);

  const scrollToPricingTiers = () => {
    if (pricingTiersRef.current) {
      pricingTiersRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const items = useMemo(() => {
   
    const systemContentArray = Array.isArray(systemContent) ? systemContent : [];
    return systemContentArray.reduce((s, r) => {
      if (!s[r.type]) {
        s[r.type] = [];
      }
      s[r.type].push(r);
      return s;
    }, {});
  }, [systemContent]);

  const settings = {
    main: {
      dots: true,
      arrows: true,
      fade: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 20000,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
    sponsors: {
      dots: true,
      arrows: true,
      fade: false,
      infinite: true,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 18000,
      slidesToShow: isLargeScreen ? 3 : 1,
      slidesToScroll: isLargeScreen ? 3 : 1,
    },
    testimonials: {
      dots: true,
      arrows: true,
      fade: false,
      infinite: true,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 16000,
      slidesToShow: isLargeScreen ? 3 : 1,
      slidesToScroll: isLargeScreen ? 3 : 1,
    },
  };

  const handleClick = () => {
    navigate("/sponsorForm");
  };

  return (
    <Box>
      <Navbar title="Home" isLanding={true} />
      <VStack w="100%">
        <Box
          className="block flex"
          flexDirection={isLargeScreen ? "row" : "column"}
          w="100%"
          h="50%"
          p="40px"
          justifyContent="center"
          alignItems="center"
          bgGradient="linear(to-tl, orange.100, orange.50, white)"
        >
          {/* Left Content */}
          <Box
            flex="1"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            textAlign="left"
            p="20px"
            maxW="600px"
          >
            <Text fontWeight="bold" fontSize="5xl" mb="10px" color="#032c47" fontSize="56px">
              Shaping Futures. <br />
              <Text as="span" color="orange.400">
                Building Trades.
              </Text>
            </Text>
            <Text fontSize="lg" color="gray.600" mb="30px">
              Empowering the Future of Trades: Connect, Learn, and Build with <strong>
                <Text as="span" color="orange.400">
                  U
                </Text>
                TRADIE</strong> – Where Opportunities Meet Skilled Hands.
            </Text>
            <Box display="flex" gap="20px">
              <Button
                bg="#032c47" // Navy blue for "Sign Up"
                color="white"
                size="lg"
                borderRadius="full"
                px="24px"
                py="12px"
                boxShadow="md"
                _hover={{ bg: "blue.700", boxShadow: "lg" }}
                onClick={() => navigate("/signUp")}
              >
                Sign Up
              </Button>
              <Button
                bg="orange.400" // Orange for "Log In"
                color="white"
                size="lg"
                borderRadius="full"
                px="24px"
                py="12px"
                boxShadow="md"
                _hover={{ bg: "orange.500", boxShadow: "lg" }}
                onClick={() => navigate("/login")}
              >
                Log In
              </Button>
            </Box>

          </Box>

          {/* Right Image Section */}
          <Box
            position="relative"
            width="900px"
            height="700px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              position="absolute"
              top="50%"
              left="50%"
              width="350px"
              height="350px"
              borderRadius="50%"
              overflow="hidden"
              backgroundColor="orange"
              transform="translate(-50%, -50%)"
            ></Box>
            <Box
              position="relative"
              top="-100px"
              left="-130px"
              width="150px"
              height="150px"
              borderRadius="20px"
              overflow="hidden"
            >
              <Image src={IMG1} alt="Top Left Image" width="100%" height="100%" />
            </Box>
            <Box
              position="absolute"
              top="60px"
              left="400px"
              width="400px"
              height="400px"
              borderRadius="20px"
              overflow="hidden"
            >
              <Image src={IMG2} alt="Center Large Image" width="100%" height="100%" />
            </Box>
            <Box
              position="absolute"
              top="380px"
              left="220px"
              width="180px"
              height="180px"
              borderRadius="20px"
              overflow="hidden"
            >
              <Image src={IMG3} alt="Bottom Left Image" width="100%" height="100%" />
            </Box>
            <Box
              position="absolute"
              top="460px"
              left="450px"
              width="150px"
              height="150px"
              borderRadius="20px"
              overflow="hidden"
            >
              <Image src={IMG4} alt="Bottom Right Image" width="100%" height="100%" />
            </Box>
          </Box>
        </Box>

        <Box
          p="50px"
          w="100%"
          backgroundColor=" var(--orange)"
          backgroundImage={background}
          justifyContent="center"
          alignContent="center"
        >
          <Box
            w="75%"
            justifyContent="center"
            alignContent="center"
            marginX="auto"
          >
            <Carousel items={items["main"] ?? []} settings={settings.main}>
              {(item) => {
                return (
                  <Box w="100%">
                    {isLargeScreen && (
                      <HStack alignContent="center" justifyContent="center">
                        {item.hasImage && (
                          <Image
                            height="100%"
                            width="auto"
                            src={`${getServerUrl()}?class=ControllerAccess&method=getSystemContentImage&id=${item.id
                              }`}
                            alt={item.title}
                          />
                        )}
                        <VStack w="100%" p="5px">
                          <Box
                            className="brand-text"
                            fontSize={isLargeScreen ? "40px" : "20px"}
                            color="white"
                          >
                            {item.title}
                          </Box>
                          <Box textAlign="justify">{item.body}</Box>
                        </VStack>
                      </HStack>
                    )}
                    {!isLargeScreen && (
                      <VStack alignContent="center" justifyContent="center">
                        {item.hasImage && (
                          <Image
                            height="100%"
                            width="auto"
                            src={`${getServerUrl()}?class=ControllerAccess&method=getSystemContentImage&id=${item.id
                              }`}
                            alt={item.title}
                          />
                        )}
                        <VStack w="100%" p="5px">
                          <Box
                            className="brand-text"
                            fontSize={isLargeScreen ? "40px" : "20px"}
                            color="white"
                          >
                            {item.title}
                          </Box>
                          <Box textAlign="justify">{item.body}</Box>
                        </VStack>
                      </VStack>
                    )}
                  </Box>
                );
              }}
            </Carousel>
          </Box>
        </Box>

        {/* show that component here */}
        <Box ref={pricingTiersRef}>
          <PricingTiers />
        </Box>

        {(items["testimonials"] ?? []).length > 0 && (
          <Box p="50px" w="75%">
            <HStack
              justifyContent={isLargeScreen ? "space-between" : "center"}
              m="30px"
              wrap="wrap"
            >
              <Box fontWeight="bold">Testimonials:</Box>
              <Button onClick={handleClick}>
                Submit a Testimonial
              </Button>
            </HStack>
            <Carousel
              items={items["testimonials"] ?? []}
              settings={settings.testimonials}
            >
              {(item, index) => {
                const images = [img1, img2, img3];
                return (
                  <Box w="100%" p="30px">
                    <VStack
                      w="100%"
                      alignContent="center"
                      justifyContent="center"
                    >
                      <Image src={images[index % images.length]} alt={`Image ${index + 1}`} boxSize="100px" />
                      <Box textAlign="justify">{item.body}</Box>
                    </VStack>
                  </Box>
                );
              }}
            </Carousel>
          </Box>
        )}
        <G2Button27
          text="Create an account"
          onClick={scrollToPricingTiers}
        />
        <Box p="50px" w="75%">
          <HStack
            justifyContent={isLargeScreen ? "space-between" : "center"}
            m="30px"
            wrap="wrap"
          >
            <Box fontWeight="bold" style={{ marginLeft: "-20px" }}>Sponsors:</Box>


            <Button>Become a Sponsor</Button>
          </HStack>
          <HStack
            justifyContent="space-between"
            mt="20px"
            spacing="20px"
            wrap="wrap"
          >
            <Image
              src={i1}
              alt="Sponsor 1"
              boxSize="100px"
              objectFit="contain"
            />
            <Image
              src={i2}
              alt="Sponsor 2"
              boxSize="100px"
              objectFit="contain"
            />
            <Image
              src={i3}
              alt="Sponsor 3"
              boxSize="100px"
              objectFit="contain"
            />
          </HStack>
        </Box>
        <Box>
          <HStack>
            <Box>
              <NavLink to="/about">About</NavLink>
            </Box>
            <Box> | </Box>
            <Box>
              <NavLink to="/overview">Overview</NavLink>
            </Box>
            <Box> | </Box>
            <Box>
              <NavLink to="/pricing">Pricing</NavLink>
            </Box>
            <Box> | </Box>
            <Box>
              <NavLink to="/contact">Customer Support</NavLink>
            </Box>
            <Box> | </Box>
            <Box>
              <NavLink to="/terms">Terms &amp; Conditions</NavLink>
            </Box>
            <Box> | </Box>
            <Box>
              <NavLink to="/privacy">Privacy Policy</NavLink>
            </Box>
          </HStack>
        </Box>

        <Box w="100%" className="p-5 text-center">
          <Box className="centering-w">
            <Box className="flex">
              {/* {social.map((val, i) => (
                <Image
                  key={i}
                  src={val}
                  width="25px"
                  height="25px"
                  className="mr-5 normal-hover"
                />
              ))} */}
              {social.map((item, i) => (
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={i}
                >
                  <Image
                    src={item.src}
                    width="25px"
                    height="25px"
                    className="mr-5 normal-hover"
                  />
                </a>
              ))}
            </Box>
          </Box>
        </Box>

        <Box
          padding="15px"
          marginTop="15px"
          w="100%"
          justifyContent="center"
          alignContent="center"
          marginX="auto"
          textAlign="center"
          backgroundColor="var(--blue)"
          color="white"
        >
          Copyright <UTradieText isHeading={true} /> PTY LTD
        </Box>
      </VStack>
    </Box>
  );
};

export default LandingPage;