
import {
    Box,
    Text,
    Image,
    Divider,
    Card,
    CardBody,
    Select,
    Progress,
    Circle,
    useToast,
    InputGroup,
    Input,
    InputRightElement,
    IconButton,
    Checkbox,
    HStack,
    Button,
    VStack,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useStateContext } from "../ContextProvider/Context";
import Navbar from "../Components/Navbar/Navbar";
import UniversalLeftSideBar from "../Components/Common/UniversalLeftSideBar";
import plan from "../assets/create/plan.png";
import G2Button20 from "../Components/Common/G2Button20";
import { getAffiliateCodes, getAffiliateCodeUsers, saveAffiliateCode, getUserCredits } from "../Store/Actions/otherAction";
import { useDispatch } from "react-redux";
import g2Toast from "../Custom/g2Toast";
import {EditIcon , CloseIcon} from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Editable,
    EditableInput,
    EditableTextarea,
    EditablePreview,
    FormControl,
    FormLabel,
    Grid,
    Switch,
    Textarea,
} from '@chakra-ui/react'
import { formatCurrency } from "../helpers/formatters";
import InnerLeftSideBar from "../Components/InnerLeftSideBar";

function AffiliatePage() {
    const [affiliateCode, setAffiliateCode] = useState('');
    const [editingCode, setEditingCode] = useState("");
    const [discount, setDiscount] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [codeCreate, setCodeCreate] = useState(""); 
    const [codes, setCodes] = useState([])
    const {onOpen, onClose } = useDisclosure()
    const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();
    const [notes, setNotes] = useState([
        {
          text: "Spoke with John from Smith Constructions. Interested in the Business Account for managing his team. Wants a demo next week.",
          date: "19-11-2024",
        },
        {
          text: "John expressed concerns about the time it might take to onboard his team. Shared a video tutorial to demonstrate the quick setup process.",
          date: "17-11-2024",
        },
        {
          text: "John asked about integration with existing accounting software. Confirmed UTRADIE's compatibility with Xero and sent a follow-up email with documentation.",
          date: "14-11-2024",
        },
      ]);
      const [newNote, setNewNote] = useState("");


    const dispatch = useDispatch();
    const toast = useToast();

    const affiliateCodes = useSelector((state) => state.Other.affiliateCodes);
    const affiliateCodeUsers = useSelector((state) => state.Other.affiliateCodeUsers);
    const userCredits = useSelector((state) => state.Other.userCredits);
    const[openPopUp , setOpenPopUp] = useState(false);
    

    useEffect(() => {
        dispatch(getAffiliateCodes(toast));
    }, []);

    useEffect(() => {
        dispatch(getAffiliateCodeUsers(toast));
    }, []);

    useEffect(() => {
        dispatch(getUserCredits(toast));
    }, []);

    const handleCreateCode = () => {
        // Check if the input starts with a letter
        if (!/^[a-zA-Z]/.test(codeCreate)) {
            g2Toast({ toast, status: "error", title: "Affiliate Code must be 5 characters or more and start with a letter" })
          return;
        }
      
        // Check if the input has a length of 5 or more
        if (codeCreate.length < 5) {
            g2Toast({ toast, status: "error", title: "Affiliate Code must be 5 characters or more and start with a letter" })
          return;
        }
      
        // If all conditions are met, add the code
        setCodes([...codes, codeCreate]);
        setCodeCreate(""); // Reset the input field
      };
    const addNote = () => {
        if (newNote.trim()) {
          setNotes([{ text: newNote, date: new Date().toLocaleDateString() }, ...notes]);
          setNewNote("");
        }
      };
    const createAffiliateCodeCallback = useCallback(() => {
        dispatch(saveAffiliateCode(toast, { code:editingCode , discount , startDate , endDate}));
        setAffiliateCode("");  // Clear the input field after saving
      }, [editingCode , discount , startDate , endDate]);

    const totalRebate = useMemo(() => {
        return affiliateCodeUsers.reduce((s, r) => {
            s += r.totalRebate;
            return s;
        }, 0);
    }, [affiliateCodeUsers]);

    const [isOpen, setIsOpen] = useState(true)
    const [innerLeftWidth, setInnerLeftWidth] = useState(250);
    useEffect(() => {
        if (isOpen) {
            setInnerLeftWidth(250);
        } else {
            setInnerLeftWidth(80);
        }
    }, [isOpen]);

    const handleEdit = (code) => {
        console.log(code)
        setEditingCode(code)
        setOpenPopUp(true);     // Open the edit modal
      };
      console.log(editingCode , "EC")


      const formatDateWithTimeZone = (date) => {
        const options = {
          timeZone: "UTC", // Change this to your desired time zone
          timeZoneName: "short",
        };
        return new Date(date).toLocaleDateString("en-US", options);
      };
      

    return <Box w="100%" minH="100vh" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
        <Box w="100%">
            <Navbar title="Create" />
        </Box>
        <Box w="100%" className="flex-box">
            <Box className="universal-leftBar-w">
                <Box w="100%" h="100vh" className="sticky top-0">
                    <UniversalLeftSideBar />
                </Box>
            </Box>
            <Box className="universal-body-w flex">
                <HStack height="100%" w="100%">
                    <Box h="100%" w={innerLeftWidth + "px"}>
                        <InnerLeftSideBar isOpen={isOpen} setIsOpen={setIsOpen}>
                            <Text fontSize="16px" className="font-semibold mx-[10px]">Affiliate Payment Summary</Text>
                            <Box w="100%" className="flex">
                                <Text fontSize="16px" className="font-semibold mx-[10px]">Earned</Text>
                                <Text fontSize="16px" className="font-semibold mx-[10px]">{formatCurrency(totalRebate)}</Text>
                            </Box>
                            <Box w="100%" className="flex">
                                <Text fontSize="16px" className="font-semibold mx-[10px]">Received</Text>
                                <Text fontSize="16px" className="font-semibold mx-[10px]">{formatCurrency(userCredits.totalcredits)}</Text>
                            </Box>
                            <Box w="100%" className="flex">
                                <Text fontSize="16px" className="font-semibold mx-[10px]">Total</Text>
                                <Text fontSize="16px" className="font-semibold mx-[10px]">{formatCurrency(totalRebate - userCredits.totalcredits)}</Text>
                            </Box>
                            <br />
                            <Text fontSize="16px" className="font-semibold mx-[10px]">Affiliate Codes</Text>
                            {codes.map((code) => (
                                <div key={code.id} className="flex justify-between items-center mx-[10px]">
                                    <Text fontSize="16px">{code}</Text>
                                    <EditIcon
                                        className="ml-2 cursor-pointer"
                                        onClick={() => handleEdit(code)}  // Open modal for editing
                                    />
                                </div>
                            ))}
                        </InnerLeftSideBar>
                    </Box>
                    <Box h="100%" w={`calc(100% - ${innerLeftWidth}px)`}>
                        <Box w="100%" h="80px" bg={G2_color_2} className="flex-box centering-h px-[30px] shadow">
                            <Box w="100%" className="flex-box justify-between">
                                <Box className="flex-box text-left centering-h">
                                    <Image boxSize="30px" src={plan} alt='icon' />
                                    <Text fontSize="16px" className="font-semibold mx-[10px]">Affiliate Code</Text>
                                </Box>
                                <Box className="flex-box text-right centering-h">
                                    <Box className="mr-5 normal-hover flex">
                                        <Input type="text" value={codeCreate} onChange={(e) => setCodeCreate(e.target.value)}></Input>
                                    </Box>
                                    <G2Button20 widthVal="150px" heightVal="35px" text="Create Code" onClick={handleCreateCode} />
                                </Box>
                            </Box>
                        </Box>
                        <TableContainer>
                            <Table variant='striped'>
                                <Thead>
                                    <Tr>
                                        <Th>User</Th>
                                        <Th>Affiliate Code</Th>
                                        <Th>Paid Days</Th>
                                        <Th>Affiliate Payment</Th>
                                        {affiliateCodeUsers && (
                                            <>
                                                <Th>DISCOUNT</Th>
                                                <Th>OFFER DATE</Th>
                                                <Th>OFFER PERIOD</Th>
                                                <Th>SIGN UP DATE</Th>
                                                <Th>NOTES</Th>
                                                <Th>EMAIL</Th>
                                                <Th>TASK</Th>
                                                <Th>REMINDER</Th>
                                            </>
                                        )}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {affiliateCodeUsers.map(r =>
                                        <Tr key={r.id}>
                                            <Td>{r.name}</Td>
                                            <Td>{r.affiliateCode}</Td>
                                            <Td>{r.totalDays}</Td>
                                            <Td>{formatCurrency(r.totalRebate)}</Td>
                                            <Td>1</Td>
                                            <Td>1</Td>
                                            <Td>1</Td>
                                            <Td>1</Td>
                                            <Td>1</Td>
                                            <Td><i class="fa-solid fa-envelope"></i></Td>
                                            <Td>1</Td>
                                            <Td>1</Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                </HStack>
            </Box >
        </Box >
        <Modal size="lg" isOpen={openPopUp} onClose={() => setOpenPopUp(false)}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Edit Code</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mb={4}>
                        <FormLabel>Affiliate Code</FormLabel>
                        <Input
                            value={editingCode}
                            onChange={(e) => setEditingCode(e.target.value)}
                            style={{ textAlign: "center" }}
                        />
                    </FormControl>

                    <FormControl mb={4}>
                        <FormLabel>Discount</FormLabel>
                        <Input
                            value={discount || ""}
                            onChange={(e) => setDiscount(e.target.value)}
                            style={{ textAlign: "center" }}
                        />
                    </FormControl>

                    <Grid templateColumns="1fr 1fr" gap={4} mb={4}>
                        <FormControl>
                            <FormLabel>Start Date</FormLabel>
                            <Input
                                type="date"
                                value={startDate || ""}
                                onChange={(e) => setStartDate(formatDateWithTimeZone(e.target.value))}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>End Date</FormLabel>
                            <Input
                                type="date"
                                value={endDate || ""}
                                onChange={(e) => setEndDate(formatDateWithTimeZone(e.target.value))}
                            />
                        </FormControl>
                    </Grid>

                    <Button colorScheme="green" onClick={() => createAffiliateCodeCallback(editingCode)}>
                        Save Changes
                    </Button>
                </ModalBody>
            </ModalContent>
        </Modal>


        {/* <Box
            borderWidth="1px"
            borderRadius="md"
            boxShadow="lg"
            p={4}
            maxW="400px"
            bg="white"
            overflowY="auto"
        >

            <HStack justify="space-between" mb={4}>
                <Text fontSize="lg" fontWeight="bold">
                    NOTES
                </Text>
                <IconButton
                    aria-label="Close"
                    icon={<CloseIcon />}
                    size="sm"
                    variant="ghost"
                />
            </HStack>


            <VStack spacing={2} mb={4}>
                <Textarea
                    placeholder="Enter Notes.."
                    value={newNote}
                    onChange={(e) => setNewNote(e.target.value)}
                />
                <Button
                    onClick={addNote}
                    colorScheme="teal"
                    size="sm"
                    alignSelf="flex-end"
                >
                    SAVE
                </Button>
            </VStack>

            <Divider />

            
            <VStack spacing={3} align="stretch" mt={4} overflowY="auto" maxH="300px">
                {notes.map((note, index) => (
                    <Box key={index} p={3} bg="gray.50" borderRadius="md" boxShadow="sm">
                        <Text fontSize="sm" mb={1}>
                            {note.text}
                        </Text>
                        <Text fontSize="xs" textAlign="right" color="gray.500">
                            {note.date}
                        </Text>
                    </Box>
                ))}
            </VStack>
        </Box> */}

    </Box>

    
}

export default AffiliatePage;