import React from "react";
import { useStateContext } from "../../ContextProvider/Context";

const G2Button37 = ({ widthVal, heightVal, marginVal , text, icon, onClick, children }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

  return (
    <button
      onClick={onClick}
      style={{
        width: `${widthVal}`,
        height: `${heightVal}`,
        marginLeft:`${marginVal}`,
        borderRadius: "100%", // Makes the button circular
      }}
      className="bg-[var(--orange)] text-white hover:bg-[#2a956e] flex justify-center items-center font-semibold"
    >
      {children}
    </button>
  );
};

export default G2Button37;
