import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  IconButton,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { ChatIcon } from "@chakra-ui/icons";
import {
  getChatMessages,
  sendChatMessage,
} from "../../Store/Actions/chatAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { formatDateTime } from "../../helpers/formatters";
import { Link } from "react-router-dom";
import { MdOutlineLiveHelp } from "react-icons/md";

function Chat({ targetType, targetId, children }) {
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state.Auth.user);
  const messageRef = useRef([]);

  const toast = useToast();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getChatMessages(toast, targetType, targetId));
  }, [targetType, targetId]);

  const chatLogs = useSelector((state) => state.Chat.chatLogs);

  const sendMessage = useCallback(() => {
    dispatch(sendChatMessage(toast, targetType, targetId, message));
    setMessage("");
  }, [message, targetType, targetId]);

  useEffect(() => {
    if (messageRef.current.length == 0) {
      return;
    }
    messageRef.current[messageRef.current.length - 1].scrollIntoView();
  }, [messageRef, chatLogs]);

  const onEnter = useCallback((e, callback) => {
    if (e.key != "Enter") {
      return;
    }

    callback();
  }, []);

  const key = useMemo(() => {
    return user?.id + "|" + (targetType ?? "") + "|" + (targetId ?? "");
  }, [user, targetType, targetId]);

  //   const chatWindow = (
  //     <Box className="chat-window">
  //       <Box className="chat-messages">
  //         {(chatLogs[key] ?? []).map((r, i) => (
  //           <Box
  //             key={r.id}
  //             className={
  //               "chat-message " + (r.isSent ? "chat-sent" : "chat-received")
  //             }
  //             ref={(el) => (messageRef.current[i] = el)}
  //           >
  //             <Box>{r.text}</Box>
  //             <Box textAlign={r.isSent ? "right" : "left"}>
  //               {r.name} {formatDateTime(r.created_at)}
  //             </Box>
  //           </Box>
  //         ))}
  //       </Box>
  //       <Box>
  //         <Stack spacing={4} direction="row" align="center">
  //           <Input
  //             placeholder="Message..."
  //             value={message}
  //             onChange={(e) => setMessage(e.target.value)}
  //             onKeyDown={(e) => onEnter(e, sendMessage)}
  //           />
  //           <Button className="chat-button" onClick={sendMessage}>
  //             Send
  //           </Button>
  //         </Stack>
  //       </Box>
  //     </Box>
  //   );

  const chatWindow = (
    <Box
      className="chat-window"
      display="flex"
      flexDirection="column"
      height="100vh" // Full height for the chat window
      width="100%" // Ensures no horizontal scrolling
      bg="white" // Optional background color for clarity
    >
      <Box
        className="chat-messages"
        flex="1" // Makes it grow to occupy available space
        overflowY="auto" // Only vertical scrolling
        overflowX="hidden" // Prevents horizontal scrolling
        padding="10px"
      >
        {(chatLogs[key] ?? []).map((r, i) => (
          <Box
            key={r.id}
            className={
              "chat-message " + (r.isSent ? "chat-sent" : "chat-received")
            }
            ref={(el) => (messageRef.current[i] = el)}
            mb="10px"
          >
            <Box>{r.text}</Box>
            <Box
              textAlign={r.isSent ? "right" : "left"}
              fontSize="sm"
              color="gray.500"
            >
              {r.name} {formatDateTime(r.created_at)}
            </Box>
          </Box>
        ))}
      </Box>
      <Box padding="10px" borderTop="1px solid #e0e0e0" bg="gray.50">
        <Stack spacing={4} direction="row" align="center">
          <Input
            placeholder="Message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => onEnter(e, sendMessage)}
            bg="white"
            borderRadius="md"
          />
          <Button
            className="chat-button"
            onClick={sendMessage}
            colorScheme="blue"
          >
            Send
          </Button>
        </Stack>
      </Box>
    </Box>
  );

  if (children !== undefined) {
    return (
      <Box position="relative">
        <Box onClick={() => setIsOpen(!isOpen)}>{children}</Box>
        {isOpen && (
          <Box position="absolute" zIndex={1000}>
            {chatWindow}
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box position="fixed" bottom="2vh" right="2vw" zIndex={1000}>
      <Box className="flex-box text-left centering-h">
        <IconButton
          className="mr-5"
          isRound={!isOpen}
          variant="outline"
          bg="#032c47"
          color="grey"
          fontSize="20px"
          icon={<ChatIcon />}
          onClick={() => setIsOpen(!isOpen)}
          title="Help Chat"
        />
        <Link target="_blank" to="https://forms.gle/fZGaEgVDEEUfpdK88">
          <IconButton
            className="mr-5"
            isRound={!isOpen}
            variant="outline"
            bg="#032c47"
            color="grey"
            fontSize="20px"
            icon={<MdOutlineLiveHelp />}
            title="Help Request Form"
          />
        </Link>
      </Box>
      {isOpen && chatWindow}
    </Box>
  );
}

export default Chat;
