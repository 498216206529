import {React, useState} from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Radio,
  RadioGroup,
  Stack,
  Heading,
  Textarea
} from "@chakra-ui/react";

const SponsorForm = () => {
// const [selectedImage, setSelectedImage] = useState(null);
// const handleImageChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setSelectedImage(file);
//     }
//   };
  return (
    <Flex
      align="center"
      justify="center"
      minH="100vh"
      bg="gray.50"
    >
      <Box
        w="full"
        maxW="md"
        p={6}
        boxShadow="lg"
        bg="white"
        borderRadius="md"
      >
        <Heading as="h2" size="md" textAlign="center" mb={4} color="orange.500">
          Testimonials 
        </Heading>
        <Text fontSize="lg" fontWeight="bold" textAlign="center" mb={6}>
          Information Required 
        </Text>
        <form>
          <FormControl isRequired mb={4}>
            <FormLabel>Full Name</FormLabel>
            <Input placeholder="Enter your full name" />
          </FormControl>
          <FormControl isRequired mb={4}>
            <FormLabel>Bussiness Name</FormLabel>
            <Input type="text" placeholder="Enter your Bussiness Name" />
          </FormControl>
          <FormControl isRequired mb={4}>
                      <FormLabel>Password</FormLabel>
                      <Input
                          type="file"
                          accept="image/*"
                          // onChange={handleImageChange}
                      />
                  </FormControl>
                  {/* {selectedImage && (
                      <Box>
                          <Image
                              src={selectedImage}
                              alt="Preview"
                              boxSize="150px"
                              objectFit="cover"
                              borderRadius="md"
                              border="1px solid"
                              borderColor="gray.200"
                          />
                      </Box>
                  )} */}
          <FormControl isRequired mb={4}>
            <FormLabel>Message</FormLabel>
            <Textarea placeholder='Here is a sample placeholder' />
          </FormControl>
          {/* <FormControl isRequired mb={4}>
            <FormLabel>Select your profile</FormLabel>
            <Select placeholder="Choose Profile">
              <option value="student">Student</option>
              <option value="professional">Professional</option>
              <option value="other">Other</option>
            </Select>
          </FormControl>
          <FormControl isRequired mb={4}>
            <FormLabel>Address</FormLabel>
            <Input placeholder="Enter your address" />
          </FormControl>
          <FormControl isRequired mb={4}>
            <FormLabel>Phone Number</FormLabel>
            <Input type="tel" placeholder="Enter your phone number" />
          </FormControl>
          <FormControl isRequired mb={4}>
            <FormLabel>Date of Birth</FormLabel>
            <Input type="date" />
          </FormControl>
          <FormControl isRequired mb={4}>
            <FormLabel>Are You Employed?</FormLabel>
            <RadioGroup>
              <Stack direction="row">
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Stack>
            </RadioGroup>
          </FormControl> */}
          <Button colorScheme="teal" width="full" mt={4}>
            Next
          </Button>
        </form>
        <Text mt={4} fontSize="sm" textAlign="center">
          Already have an account?{" "}
          <Text as="span" color="blue.500" cursor="pointer">
            Login
          </Text>
        </Text>
      </Box>
    </Flex>
  );
};

export default SponsorForm;
