import React from "react";
import { EditIcon } from "@chakra-ui/icons";
import { useStateContext } from "../../ContextProvider/Context";

const G2Button38 = ({ widthVal = "80px", heightVal = "80px", text, onClick }) => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } = useStateContext();

  return (
    <button
      onClick={onClick}
      style={{
        width: `${widthVal}`,
        height: `${heightVal}`,
        borderRadius: "12px", // Rounded corners
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "var(--orange)", // Replace with your orange variable
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
      className="hover:bg-[#2a956e] text-white font-semibold text-sm"
    >
      <EditIcon boxSize="20px" className="mb-1" /> {/* Icon on top */}
      {text && <span>{text}</span>} {/* Text below the icon */}
    </button>
  );
};

export default G2Button38;
