
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

import {
  Box,
  Text,
  Image,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Card,
  CardBody,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  Divider,
  Flex,
} from "@chakra-ui/react";

import {
  SearchIcon,
  ChevronDownIcon,
  EditIcon,
  SmallAddIcon,
  SmallCloseIcon,
  ArrowForwardIcon,
} from "@chakra-ui/icons";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, verifyToken } from "../../utils";
import {
  getContentList,
  searchContentList,
  getMyContentInfo,
  getMyContent,
  getSomeContent,
  makeContent,
  addToBusket,
  removeToBusket,
  getBusket,
  getCategory,
  addTreeItem,
  removeTreeItem,
  removeTreeNode,
  incViewCnt,
} from "../../Store/Actions/marketplaceAction";

import g2Toast from "../../Custom/g2Toast";
import isEmptyObject from "../../Custom/isEmptyObject";
import isEmptyValue from "../../Custom/isEmptyValue";

import G2ConfirmAlert from "../Common/G2ConfirmAlert";

import NodeAddModal from "./NodeAddModal";

import G2Button2 from "../Common/G2Button2";
import G2Button3 from "../Common/G2Button3";
import G2Button14 from "../Common/G2Button14";
import G2Button37 from "../Common/G2Button37"
import G2Button20 from "../Common/G2Button20";
import G2Button38 from "../Common/G2Button38";
import G2Button21 from "../Common/G2Button21";

import main1 from "../../assets/marketplace/main1.png";
import main2 from "../../assets/marketplace/main2.png";
import main3 from "../../assets/marketplace/main3.png";

import content1 from "../../assets/marketplace/content1.png";
import content2 from "../../assets/marketplace/content2.png";
import content3 from "../../assets/marketplace/content3.png";
import content4 from "../../assets/marketplace/content4.png";
import content5 from "../../assets/marketplace/content5.png";
import content6 from "../../assets/marketplace/content6.png";

import newer from "../../assets/marketplace/new.png";
import enrolled from "../../assets/marketplace/enrolled.png";
import seen from "../../assets/marketplace/seen.png";
import buy1 from "../../assets/marketplace/buy1.png";
import buy2 from "../../assets/marketplace/buy2.png";

import edit from "../../assets/marketplace/edit.png";
import { getServerUrl } from "../../helpers/location";
import { getFileUrl } from "../../helpers/general";

const btnGroup = [
  "Featured",
  "Price: Low to High",
  "Price: High to Low",
  "Newly Added",
  "Most Enrolled",
];

const MainMarketForm = () => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const toast = useToast();

  const user = useSelector((state) => state.Auth.user);
  const contentList = useSelector((state) => state.Marketplace.contentList);
  const busket = useSelector((state) => state.Marketplace.busket);
  const category = useSelector((state) => state.Marketplace.category);
  const posted = useSelector((state) => state.Marketplace.posted);
  const draft = useSelector((state) => state.Marketplace.draft);

  const [screenSize, setScreenSize] = useState(1366);

  const [isNAddOpen, setIsNAddOpen] = useState(false);

  const [categoryTitle, setCategoryTitle] = useState("");
  const [categoryId, setCategoryId] = useState("");

  const [sort, setSort] = useState("Featured");
  const [currentPage, setCurrentPage] = useState(1);

  const [searchText, setSearchText] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [treeItem, setTreeItem] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null);

  const [isDopen, setIsDopen] = useState(null);

  const token = getToken();

  useEffect(() => {
    setScreenSize(window.innerWidth);
    window.addEventListener("resize", setScreenSize);

    dispatch(getCategory(toast));
    dispatch(getContentList(toast, sort, currentPage));
    dispatch(getBusket(toast));
  }, [window.innerWidth]);

  // useEffect(() => {
  //   setCategoryTitle(category[0]?.item);
  //   setCategoryId(category[0]?._id);
  // }, [category]);

  useEffect(() => {
    if (category && category.length > 0) {
      // Check if category is defined and has at least one item
      setCategoryTitle(category[0].item);
      setCategoryId(category[0]._id);
    }
  }, [category]);

  const openNAddModal = () => {
    setIsNAddOpen(true);
  };

  const closeNAddModal = () => {
    setIsNAddOpen(false);
  };

  const openDeleteModal = (i) => {
    setIsDopen(i);
  };

  const closeDeleteModal = () => {
    setIsDopen(null);
  };

  const categoryChange = (e) => {
    const value = e.target.options[e.target.selectedIndex].text;
    setCategoryTitle(value);
    setCategoryId(e.target.value);
  };

  const handleNodeChange = (e) => {
    const nodeId = e.target.value;

    dispatch(getSomeContent(toast, sort, categoryId, nodeId));
  };

  const goToView = (val) => {
    dispatch(makeContent(navigate, 1, val));
    dispatch(incViewCnt(toast));
  };

  const goToEdit = (val) => {
    dispatch(makeContent(navigate, 2, val));
  };

  const onClickNew = () => {
    navigate("/marketplace/register");
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSortChange = (val) => {
    setSort(val);

    dispatch(getContentList(toast, val, currentPage));
  };

  const limitObjectKeys = (originalObject, allowedKeys) => {
    return allowedKeys.reduce((acc, key) => {
      if (originalObject.hasOwnProperty(key)) {
        acc[key] = originalObject[key];
      }
      return acc;
    }, {});
  };

  const handleSearchClick = () => {
    dispatch(searchContentList(toast, searchText));
  };

  const handleClickBusket = (id) => {
    if (busket?.includes(id)) {
      dispatch(removeToBusket(toast, id));
    } else {
      dispatch(addToBusket(toast, id));
    }
  };

  const handleItemAdd = () => {
    if (!isEmptyValue(treeItem)) {
      dispatch(addTreeItem(toast, treeItem));
      setTreeItem("");
    } else {
      g2Toast({ toast, status: "error", title: "Input node name!" });
    }
  };

  const handleNodeAdd = (id) => {
    openNAddModal();
    setSelectedItemId(id);
  };

  const handleItemRemove = (itemId) => {
    setIsDopen(null);

    dispatch(removeTreeItem(toast, itemId));
  };

  const handleNodeRemove = (itemId, nodeId) => {
    dispatch(removeTreeNode(toast, itemId, nodeId));
  };

  const handleAllClick = () => {
    dispatch(getContentList(toast, sort, currentPage));
  };

  const handleGetMyContentInfo = () => {
    dispatch(getMyContentInfo(toast));
  };

  const handleMyNodeClick = (cond) => {
    if (cond == 1) {
      dispatch(getMyContent(toast, "Posted"));
    }
    if (cond == 2) {
      dispatch(getMyContent(toast, "Draft"));
    }
  };

  const handleNodeClick = (itemId, nodeId) => {
    dispatch(getSomeContent(toast, sort, itemId, nodeId));
  };

  return (
    <Box
      minHeight="100vh"
      style={{ background: G2_color_1, color: G2_color_3 }} // Removed template literals
    >
      <Box
        className="mt-5 px-16 w-full"
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        {/* New Button */}
        <Box className="flex-box text-left pl-[10px]">
          <G2Button38 text="New" onClick={onClickNew} />
        </Box>

        {/* Search Bar */}
        <Box style={{ flex: 1, maxWidth: "35%" }}>
          <InputGroup
            bg={G2_color_2}
            className={`${G2_theme_mode === "light" ? "border-gray-600" : "border-gray-300"
              }`}
            style={{ width: "120%", border: "1px solid", borderRadius: "10px", overflow: "hidden" }}


          >
            <InputLeftElement h="50px" className="centering">
              <SearchIcon boxSize="20px" color={G2_color_3} />
            </InputLeftElement>
            <Input
              h="50px"
              placeholder="Search here..."
              color={G2_color_3}
              className="pl-[50px] pr-[100px]"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <InputRightElement w="100px" h="50px" className="centering">
              <G2Button37
                widthVal="30px"
                heightVal="30px"
                marginVal="50px"
                onClick={handleSearchClick}
              >
                <ArrowForwardIcon />
              </G2Button37>
            </InputRightElement>
          </InputGroup>
        </Box>

        {/* Sort Button */}
        <Box className="flex-box text-right">
          <Menu>
            <MenuButton
              className="btn-small rounded-sm"
              bg="var(--orange)"
              color="white"
              _hover={{ bg: "#2a956e" }}
              style={{
                height: "40px",
                borderRadius: "12px", // Rounded corners
                display: "flex",
                flexDirection: "column",
                fontSize: "large",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                paddingTop: "5px",
                backgroundColor: "var(--orange)", // Replace with your orange variable
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              Sort <ChevronDownIcon />
            </MenuButton>
            <MenuList>
              {btnGroup.map((val, index) => (
                <MenuItem
                  key={index}
                  className="normal-hover"
                  onClick={() => handleSortChange(val)}
                >
                  <Text className="text-black">{val}</Text>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Box>
      </Box>

      {/* Main Content */}
      <Box className="px-14 flex-box">
        <Flex w="100%" flexWrap="wrap" gap="4" justifyContent="space-between">
          {!isEditable ? (
            <Box className="market-main-category p-[10px]">
              <Box
                w="100%"
                h="30px"
                fontSize="20px"
                className="pl-[15px] flex-box"
              >
                Popular categories
                {user.isAdmin && (
                  <EditIcon
                    boxSize="20px"
                    className="mt-[5px] ml-[10px] normal-hover"
                    onClick={() => setIsEditable(true)}
                  />
                )}
              </Box>
              <Box className="px-[10px] mt-[20px]">
                <G2Button14
                  widthVal="100%"
                  heightVal="35px"
                  text="View All"
                  onClick={handleAllClick}
                />
              </Box>
              <Accordion className="p-[10px] mt-2.5" allowMultiple>
                {/* My Content Section */}
                {verifyToken() && (
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        _expanded={{ bg: "#E0F1FF", color: "black" }}
                        onClick={handleGetMyContentInfo}
                      >
                        <Box
                          flex="1"
                          textAlign="left"
                          className="text-[var(--orange)] font-semibold"
                        >
                          My Content
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} bg={G2_color_2} color={G2_color_3}
                      style={{
                        maxHeight: "70px",
                        overflowY: "auto",
                      }}
                    >
                      {/* Posted Item */}
                      <Box
                        className="flex-box cursor-pointer py-[5px] normal-hover"
                        onClick={() => handleMyNodeClick(1)}
                      >
                        <Box className="market-accordin-box1">
                          <Text fontSize="16px">Posted</Text>
                        </Box>
                        <Box className="market-accordin-box2 centering-w">
                          <Text fontSize="16px">{posted}</Text>
                        </Box>
                      </Box>
                      {/* Draft Item */}
                      <Box
                        className="flex-box cursor-pointer py-[5px] normal-hover"
                        onClick={() => handleMyNodeClick(2)}
                      >
                        <Box className="market-accordin-box1">
                          <Text fontSize="16px">Draft</Text>
                        </Box>
                        <Box className="market-accordin-box2 centering-w">
                          <Text fontSize="16px">{draft}</Text>
                        </Box>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                )}

                {/* Dynamic Categories */}
                {category?.map((element, i) => (
                  <AccordionItem key={i}>
                    <h2>
                      <AccordionButton
                        _expanded={{ bg: "#E0F1FF", color: "black" }}
                      >
                        <Box flex="1" textAlign="left" className="font-semibold">
                          {element.item}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} bg={G2_color_2} color={G2_color_3}
                      style={{
                        maxHeight: "100px", // Set the maximum height of the panel
                        overflowY: "auto",  // Enable vertical scrolling when content overflows
                      }}
                    >
                      {element.nodes?.map((val, j) => (
                        <Box
                          key={j}
                          className="flex-box cursor-pointer py-[5px] normal-hover"
                          onClick={() => handleNodeClick(element._id, val.id)}
                        >
                          <Box className="market-accordin-box1">
                            <Text fontSize="16px">{val.text}</Text>
                          </Box>
                          <Box className="market-accordin-box2 centering-w">
                            <Text fontSize="16px">{val.size}</Text>
                          </Box>
                        </Box>
                      ))}
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>

            </Box>
          ) : (
            <Box className="market-main-category p-[10px]">
              <Box
                w="100%"
                h="30px"
                fontSize="20px"
                className="flex-box px-[10px]"
              >
                <G2Button20
                  widthVal="100%"
                  heightVal="35px"
                  text="Back"
                  onClick={() => setIsEditable(false)}
                />
              </Box>
              <Box w="100%" className="px-[10px]">
                <Divider
                  borderColor={G2_theme_mode === "light" ? "black" : "white"}
                  orientation="horizontal"
                  className="mt-[20px]"
                />
              </Box>
              <Box w="100%" className="flex-box px-[10px] mt-[20px]">
                <Box style={{ width: "calc(100% - 80px)" }}>
                  <Input
                    w="100%"
                    h="35px"
                    bg={G2_color_2}
                    color={G2_color_3}
                    borderRadius="2.5px"
                    value={treeItem}
                    onChange={(e) => setTreeItem(e.target.value)}
                  />
                </Box>
                <Box w="80px" className="pl-[10px]">
                  <G2Button14
                    widthVal="100%"
                    heightVal="35px"
                    text="Add"
                    onClick={handleItemAdd}
                  />
                </Box>
              </Box>
              <Accordion
                defaultIndex={[0]}
                allowMultiple
                className="p-[10px] mt-2.5"
              >
                {category?.map((element, i) => (
                  <AccordionItem key={i}>
                    <h2>
                      <AccordionButton
                        _expanded={{ bg: "#E0F1FF", color: "black" }}
                      >
                        <Box flex="1" textAlign="left">
                          {element.item}
                        </Box>
                        <SmallAddIcon
                          className="mr-[10px] normal-hover"
                          onClick={() => handleNodeAdd(element._id)}
                        />
                        <SmallCloseIcon
                          className="mr-[10px] normal-hover"
                          onClick={() => openDeleteModal(i)}
                        />
                        <G2ConfirmAlert
                          isOpen={i === isDopen}
                          onClose={closeDeleteModal}
                          handleOkClick={() => handleItemRemove(element._id)}
                        />
                        <AccordionIcon />
                        <NodeAddModal
                          isNAddOpen={isNAddOpen}
                          closeNAddModal={closeNAddModal}
                          selectedItemId={selectedItemId}
                        />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} bg={G2_color_2} color={G2_color_3}>
                      {element.nodes?.map((val, i) => (
                        <Box key={i} className="flex-box cursor-pointer py-[5px]">
                          <Box className="market-accordin-boxA">
                            <Text fontSize="16px">{val.text}</Text>
                          </Box>
                          <Box className="market-accordin-boxB centering-w">
                            <SmallCloseIcon
                              className="normal-hover"
                              onClick={() =>
                                handleNodeRemove(element._id, val.id)
                              }
                            />
                          </Box>
                        </Box>
                      ))}
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>
          )}
          <Box w={{ base: "100%", md: "48%" }} className="flex-box mt-2.5 flex-wrap gap-4">
            {(contentList || []).map((val, i) => (
              <Box key={i} className="market-main-content p-[10px]">
                <Card
                  maxW="sm"
                  className="relative rounded-lg shadow-md"
                  style={{
                    background: G2_color_2,
                    color: G2_color_3,
                    borderRadius: "12px",
                    overflow: "hidden",
                  }}
                >
                  <CardBody w="100%" padding="0">
                    {/* Card Image */}
                    <Image
                      className="w-full h-[150px] object-cover cursor-pointer"
                      src={getFileUrl(val.cover)}
                      onClick={() => goToView(val)}
                      alt={val.contentName}
                    />

                    {/* Card Content */}
                    <Box padding="15px">
                      {/* Title */}
                      <Text
                        fontSize="16px"
                        fontWeight="bold"
                        className="mt-2 line-clamp-1"
                      >
                        {val.contentName}
                      </Text>

                      {/* Description */}
                      <Text
                        fontSize="14px"
                        className={`mt-2 text-gray-600 ${G2_theme_mode === "dark" ? "text-gray-300" : ""
                          } line-clamp-2`}
                      >
                        {val.description || "No description provided."}
                      </Text>

                      {/* Price and Shopping Icon */}
                      <Box className="flex-box justify-between items-center mt-3">
                        {/* Price */}
                        <Text
                          fontSize="16px"
                          fontWeight="bold"
                          className="text-[#2AAF7F]"
                        >
                          $ {val.memberCost}
                        </Text>

                        {/* Shopping Icon */}
                        {val.userId === user._id ? (
                          <Image
                            className="cursor-pointer"
                            width="24px"
                            src={edit}
                            alt="Edit Icon"
                            onClick={() => goToEdit(val)}
                          />
                        ) : (
                          <Image
                            className="cursor-pointer"
                            width="24px"
                            src={busket?.includes(val._id) ? buy2 : buy1}
                            alt="Shopping Icon"
                            onClick={() => handleClickBusket(val._id)}
                          />
                        )}
                      </Box>
                    </Box>
                  </CardBody>
                </Card>
              </Box>
            ))}
          </Box>
        </Flex>
      </Box>

        <Box w="100%">{/* Pagination components commented out */}</Box>
      </Box>
    

  );

};

export default MainMarketForm;