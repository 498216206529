import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	Box,
	Text,
	Image,
	Card,
	CardBody,
	Input,
	useToast,
	Button,
	HStack,
	Modal,
	useDisclosure,
} from "@chakra-ui/react";

import "../../App.css";

import { useStateContext } from "../../ContextProvider/Context";
import { getToken, deleteToken, verifyToken } from "../../utils";
import { getMainData } from "../../Store/Actions/dashboardAction";
import g2Toast from "../../Custom/g2Toast";

import mycontent from "../../assets/dashboard/my-content.png";
import prizeswon from "../../assets/dashboard/prizes-won.png";
import goalscompleted from "../../assets/dashboard/goals-completed.png";
import joboffers from "../../assets/dashboard/job-offers.png";

import star from "../../assets/dashboard/star.png";

import photo from "../../assets/dashboard/photo.png";
import poll from "../../assets/dashboard/poll.png";
import reward from "../../assets/dashboard/reward.png";
import { getServerUrl } from "../../helpers/location";
import { TimeIcon } from "@chakra-ui/icons";
import { formatRelativeTime } from "../../helpers/formatters";
import ActivityCard from "../ActivityCard";
import ExternalFileLoader from "../ExternalFileLoader";
import PTour, { createStep } from "../PTour";

const pics = [
	{
		img: photo,
		text: "Photo/Video",
	},
	{
		img: poll,
		text: "Create Poll",
	},
	{
		img: reward,
		text: "Rewards",
	},
];

const MainDashboard1 = (props) => {
	const { boxW1, boxW2, boxW3 } = props;

	const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
		useStateContext();

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const toast = useToast();

	const mainData = useSelector((state) => state.Dashboard.mainData);

	const contents = useMemo(() => {
		return [
			{
				image: mycontent,
				title: "Content",
				value: mainData?.contentCnt,
			},
			/*
			{
				image: prizeswon,
				title: "Prizes Won",
				value: 0
			},
			*/
			{
				image: goalscompleted,
				title: "Goals",
				value: <Text>{mainData?.goalsCompleteCnt}/{mainData?.goalCnt}</Text>,
			},
			/*
			{
				image: joboffers,
				title: "Job Offers",
				value: mainData.jobCnt,
			},
			*/
		];
	}, [mainData]);

	useEffect(() => {
		dispatch(getMainData(toast));
	}, []);

	const [steps, setSteps] = useState([
		createStep('.step:nth-of-type(1)', 'Here is the count for your content', { position: "bottomLeft" }),
		createStep('.step:nth-of-type(2)', 'Here is your goal Count', { position: "bottomLeft" }),
		createStep('.news-feed', 'Here is where you will see your progress', { position: "topRight" }),
	]);

	return <>
		<PTour steps={steps} />
		<Box w="100%" h="100%" style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}>
			<Box w="100%" className="flex-box" h="150px">
				{/*
				
				<Box w={boxW1} p="0 10px 20px 10px" className="dash-sub-box1">
					<Card w="100%" h="100%" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
						<CardBody>
							<Text fontSize="16px" className="reviewTitle font-semibold">
								Share Your Success Story!
							</Text>
							<Box w="100%" className="pt-[20px]">
								<Input w="100%" bg={G2_color_1} borderRadius="full" placeholder="Type here ..." />
							</Box>
							<Box w="100%" className="mt-[20px] flex-box">
								{pics.map((val, i) => (
									<Box key={i} width="33%" className="flex-box centering-w">
										<Image width="110px" src={val.img} className="px-[25px] pt-[5px] normal-hover" />
										<Text width="100%" className="mt-[10px] text-center">
											{val.text}
										</Text>
									</Box>
								))}
							</Box>
						</CardBody>
					</Card>
				</Box>
				*/}
				<Box w={"100%"} className="flex-box" h="150px">
					{contents.map((r, i) => <Box className="step" key={i} h="150px" w={(100 / contents.length) + '%'} p="0 10px 20px 10px">
						<Card w="100%" h="100%" style={{ background: `${G2_color_2}`, color: `${G2_color_3}`, }}>
							<CardBody>
								<Box>
									<Box className="centering">
										<Image boxSize="40px" src={r.image} alt="content" />
									</Box>
									<Box className="centering mt-1">
										<Text className="font-semibold" fontSize="16px">{r.value}</Text>
									</Box>
									<Box className="centering">
										<Text fontSize="14px" className="text-gray-400">
											{r.title}
										</Text>
									</Box>
								</Box>
							</CardBody>
						</Card>
					</Box>)}
				</Box>
				<Box p="0 10px 20px 10px" w="100%" className="">
					<Card w="100%" h="100%" style={{ background: `${G2_color_2}`, color: `${G2_color_3}` }}>
						<CardBody>
							<Box className="flex-box" w="100%">
								<Text w="100%" fontSize="16px" className="text-[var(--orange)] text-center font-semibold">
									News Feed
								</Text>
								<Box w="100%" className="flex-box mt-[20px]" overflow="scroll" maxH="500px">
									{mainData?.activityFeedList?.map((r, i) => <ActivityCard key={r.id} item={r} />)}
								</Box>
							</Box>
						</CardBody>
					</Card>
				</Box>
			</Box>
		</Box>
	</>
};

export default MainDashboard1;
