
import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Text,
  Button,
  Icon,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getToken, deleteToken, verifyToken } from "../../utils";
import { getUserData } from "../../Store/Actions/authAction";
import { useStateContext } from "../../ContextProvider/Context";
import isEmptyValue from "../../Custom/isEmptyValue";
import Grow2_Logo from "../../assets/Grow2_Logo.png";
import UTradie_Logo from "../../assets/UTradieLogo.png";
import avatar1 from "../../assets/navbar/avatar1.png";
import avatar2 from "../../assets/navbar/avatar2.png";

import { ImTrophy, ImTicket, ImBriefcase, ImVideoCamera, ImConnection } from "react-icons/im";
import { FaBuilding, FaCog, FaIdCard, FaLifeRing, FaLink, FaReceipt, FaUnlink } from "react-icons/fa";
import { MdMarkUnreadChatAlt, MdMenuBook, MdShop, MdShop2, MdSpeed } from "react-icons/md";

import dashboard1 from "../../assets/dashboard/dashboard-icon.png";
import create1 from "../../assets/dashboard/create-icon.png";
import learn1 from "../../assets/dashboard/learn-icon.png";
import marketplace1 from "../../assets/dashboard/marketplace-icon.png";
import life1 from "../../assets/dashboard/life-icon.png";
import academy1 from "../../assets/dashboard/academy-icon.png";

import dashboard from "../../assets/navbar/dashboard-icon.png";
import create from "../../assets/navbar/create-icon.png";
import learn from "../../assets/navbar/learn-icon.png";
import marketplace from "../../assets/navbar/marketplace-icon.png";
import life from "../../assets/navbar/life-icon.png";
import academy from "../../assets/navbar/academy-icon.png";

import aboutus from "../../assets/navbar/aboutus.png";
import contactus from "../../assets/navbar/contactus.png";
import login from "../../assets/navbar/login.png";
import logout from "../../assets/navbar/logout.png";
import UTradieText from "../Common/UTradieText";
import Chat from "../Chat/Chat";
import { getServerUrl } from "../../helpers/location";

import { getFilteredMenu } from "../../helpers/menu";
import { getFileUrl } from "../../helpers/general";



function Navbar(props) {
  const { title, isLanding } = props;

  // Context and State Management
  const { setMode, G2_theme_mode, G2_color_1, G2_color_2, G2_colorf_3 } = useStateContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const token = getToken();
  const user = useSelector((state) => state.Auth.user);
  const userData = useSelector((state) => state.Auth.userData);
  const type = useSelector((state) => state.Auth.type);

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);

  // Fetch user data on token or type change
  useEffect(() => {
    if (!isEmptyValue(type)) {
      localStorage.setItem("type", type);
    }
    const cond = localStorage.getItem("type");
    if (verifyToken()) {
      dispatch(getUserData(cond));
    }
  }, [type, dispatch]);

  // Handle screen size changes
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handle navigation based on the menu title
  const handleItemClick = (menuItem) => {
    switch (menuItem) {
      case "Create":
        navigate("/create");
        break;
      case "Learn":
        navigate("/learn");
        break;
      case "Marketplace":
        navigate("/marketplace");
        break;
      case "Connections":
        navigate("/life");
        break;
      case "Academy":
        navigate("/academy/digital");
        break;
      default:
        break;
    }
  };

  // Filtered menu based on user access rights
  // const menuItems = useMemo(() => {
  //   return getFilteredMenu(user?.accessRights); // Define `getFilteredMenu` logic
  // }, [user]);

  const menuItems = [
    {
      label: "Growth & Learning",
      subItems: [
        { text: "Create", path: "/create", icon: ImVideoCamera },
        { text: "Learn", path: "/learn", icon: MdMenuBook },
        { text: "Goals", path: "/goal", icon: FaLifeRing },
      ],
    },
    {
      label: "Communication & Social",
      subItems: [
        { text: "Message", path: "/chat", icon: MdMarkUnreadChatAlt },
        { text: "Connection", path: "/life", icon: FaLink },
        { text: "Events", path: "/academy/event", icon: ImTicket },
      ],
    },
    {
      label: "Opportunities",
      subItems: [
        { text: "Jobs", path: "/academy/job", icon: ImBriefcase },
        { text: "Marketplace", path: "/marketplace", icon: FaBuilding },
        { text: "Affiliate", path: "/affiliate", icon: FaReceipt },
      ],
    },
  ];

  return (

<Box bg="#032c47" w="100%" h="80px" className="flex-box px-6" justifyContent="space-between">
  {/* Logo Section */}
  <Box className="flex-box" alignItems="center">
    <Image
      className="cursor-pointer"
      src={UTradie_Logo}
      alt="logo"
      boxSize="50px"
      onClick={() => navigate("/")}
    />
        <Text
          ml={4}
          color="white"
          fontSize="20px"
          fontWeight="bold"
          className="cursor-pointer"
          onClick={() => navigate("/")}
        >
            <Text as="span" color="orange.400">
              U
            </Text>
            TRADIE
        </Text>
  </Box>

  {/* Dropdown Menus */}
  <Box className="flex-box gap-4" alignItems="center">
    {menuItems.map((menu, index) => (
      <Menu key={index}>
        <MenuButton
          as={Button}
          bg="transparent"
          color="white"
          // _hover={{ bg: "#0e3d5c" }}
          rightIcon={<ChevronDownIcon />}
        >
          {menu.label}
        </MenuButton>
        <MenuList>
          {menu.subItems.map((item, idx) => (
            <MenuItem key={idx} onClick={() => navigate(item.path)}
            _hover={{ bg: "orange.400", color: "white" }} // Orange background and white text on hover
            // _focus={{ bg: "orange.400", color: "white" }}
            >
              <Icon
              boxSize="20px" 
              mr={2}
              color="#4B4B4B"
              as={item.icon}
              />
              <Text>{item.text}</Text>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    ))}
  </Box>

  {/* User Account Section */}
  <Box className="flex-box gap-4" alignItems="center">
    {verifyToken() ? (
      <>
        <Text color="white" fontSize="16px" fontWeight="bold">
          {user?.name}
        </Text>
        <Menu>
          <MenuButton
            as={Button}
            bg="transparent"
            color="white"
            _hover={{ bg: "orange.400", color: "white" }}
            rightIcon={<ChevronDownIcon />}
          >
            Account & Settings
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => navigate("/dashboard")}>Dashboard</MenuItem>
            <MenuItem onClick={() => navigate("/academy/digital")}>Passport</MenuItem>
            <MenuItem onClick={() => navigate("/billing")}>Billing</MenuItem>
            <MenuItem onClick={() => navigate("/setting")}>Settings</MenuItem>
            <MenuItem
              onClick={() => {
                deleteToken();
                navigate("/login");
              }}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </>
    ) : (
      <Button colorScheme="teal" onClick={() => navigate("/login")}>
        Login
      </Button>
    )}
  </Box>
</Box>

  );
}

export default Navbar;












