import React from "react";
import { useNavigate } from "react-router-dom";

const PricingTier = ({
  title,
  description,
  features,
  buttonText = "CREATE ACCOUNT",
  isForm = false,
  isHighlighted = false, // Prop to determine if the tier should be highlighted
}) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (!isForm) {
      navigate("/signUp", { state: { tierType: title } }); // Pass the tier type to the signup page
    }
  };

  return (
    <div
      className={`px-8 py-5 rounded-2xl flex flex-col border-2 transition-all duration-300 ${
        isHighlighted
          ? "border-orange-400 bg-white shadow-lg"
          : "bg-dark-blue border-transparent"
      } hover:border-orange-400`}
    >
      <h2
        className={`text-2xl font-bold mb-4 ${
          isHighlighted ? "text-orange-400" : "text-white"
        }`}
      >
        {title}
      </h2>
      <hr
        className={`mb-4 ${isHighlighted ? "border-orange-400" : "border-white"}`}
      />
      <p className={`mb-5 ${isHighlighted ? "text-black" : "text-white"}`}>
        {description}
      </p>

      <ul className={`mb-5 flex-grow ${isHighlighted ? "text-black" : "text-white"}`}>
        {features.map((feature, index) => (
          <li key={index} className="flex items-start">
            <span className="mr-2">•</span>
            <span>{feature}</span>
          </li>
        ))}
      </ul>

      <button
        onClick={handleButtonClick}
        className={`w-full py-3 px-6 rounded-full border-2 transition-all duration-300 
    bg-white border-dark-blue text-dark-blue
    hover:bg-orange-400 hover:text-white`}
      >
        {isForm ? "FILL IN FORM" : buttonText}
      </button>
    </div>
  );
};

const PricingTiers = () => {
  const tiers = [
    {
      title: "LITE",
      description: "School Students, Apprentices, Job Seekers, Parents.",
      features: [
        "My Dashboard",
        "Passport",
        "Messages",
        "Learn",
        "Jobs",
        "Events",
        "Goals",
      ],
      isHighlighted: false, // Not highlighted
    },
    {
      title: "PERSONAL",
      description:
        "Trades Workers, Tradies, Managers, Business Owners, Support People.",
      features: [
        "My Dashboard",
        "Passport",
        "Messages",
        "Create (3x courses)",
        "Learn",
        "Marketplace",
        "Connect",
        "Jobs",
        "Events",
        "Goals",
      ],
      isHighlighted: false, // Highlighted
    },
    {
      title: "BUSINESS",
      description:
        "Trade Businesses, Content Creators, Paid Mentors, Trade Brands/Sponsors.",
      features: [
        "My Dashboard",
        "Profile",
        "Messages",
        "Create (50x courses)",
        "Learn",
        "Marketplace",
        "Connect",
        "Jobs",
        "Events",
        "Goals",
        "Affiliate",
        "Control Panel (Manage your users features)",
      ],
      isHighlighted: false, // Not highlighted
    },
    {
      title: "NON-FOR-PROFIT",
      description:
        "Job Service Organisations, Industry Associations, Apprenticeship Organisations.",
      features: ["Contact us to build better industries."],
      isForm: true,
      isHighlighted: false, // Not highlighted
    },
  ];

  return (
    <div className="w-full mt-10 bg-white">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {tiers.map((tier, index) => (
            <PricingTier
              key={index}
              title={tier.title}
              description={tier.description}
              features={tier.features}
              isForm={tier.isForm}
              isHighlighted={tier.isHighlighted} // Pass highlight prop
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingTiers;
