import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Box, ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Outlet,
  Navigate,
} from "react-router-dom";

import { ContextProvider } from "./ContextProvider/Context";
import { getToken, verifyToken } from "./utils";
// import theme from "./theme";

import store from "./Store";
import Login from "./Pages/SignInPage";

import SignUpPage from "./Pages/SignUp/SignUpPage";

import LandingPage from "./Pages/LandingPage";
import OnboardingPage from "./Pages/OnboardingPage";
import CongratulationForm from "./Components/OnBoardingForm/CongratulationForm";
import GoalSettingPage from "./Pages/GoalSettingPage";
import Dashboard from "./Pages/DashboardPage";

import CFirstPage from "./Pages/Create/FirstPage";
import ContentPage from "./Pages/Create/ContentPage";
import CardEditPage from "./Pages/Create/CardEditPage";
import CardCreatePage from "./Pages/Create/CardCreatePage";
import GroupPage from "./Pages/Create/GroupPage";
import MemberEditPage from "./Pages/Create/MemberEditPage";

import LFirstPage from "./Pages/Learn/FirstPage";
import LContentPage from "./Pages/Learn/ContentPage";
import LUnitPage from "./Pages/Learn/UnitPage";
import LCardPage from "./Pages/Learn/CardPage";

import MainMarketPage from "./Pages/Marketplace/MainMarketPage";
import ViewPage from "./Pages/Marketplace/ViewPage";
import BucketPage from "./Pages/Marketplace/BucketPage";
import RegisterPage from "./Pages/Marketplace/RegisterPage";
import EditPage from "./Pages/Marketplace/EditPage";
import PreviewPage from "./Pages/Marketplace/PreviewPage";
import SocialSharePage from "./Pages/Marketplace/SocialSharePage";

import MainLifePage from "./Pages/Life/MainLifePage";
import LViewPage from "./Pages/Life/ViewPage";
import LBucketPage from "./Pages/Life/BucketPage";
import LRegisterPage from "./Pages/Life/RegisterPage";
import LEditPage from "./Pages/Life/EditPage";
import LPreviewPage from "./Pages/Life/PreviewPage";
import LSocialSharePage from "./Pages/Life/SocialSharePage";

import DigitalPassportPage from "./Pages/Academy/DigitalPassportPage";
import LeaderBoardPage from "./Pages/Academy/LeaderBoardPage";
import JobBoardPage from "./Pages/Academy/JobBoardPage";
import EventBoardPage from "./Pages/Academy/EventBoardPage";
import JobCreatePage from "./Pages/Academy/JobCreatePage";
import JobUpdatePage from "./Pages/Academy/JobUpdatePage";
import EventCreatePage from "./Pages/Academy/EventCreatePage";
import EventUpdatePage from "./Pages/Academy/EventUpdatePage";
import JobViewPage from "./Pages/Academy/JobViewPage";
import EventViewPage from "./Pages/Academy/EventViewPage";
import JobEditPage from "./Pages/Academy/JobEditPage";

import SettingPage from "./Pages/SettingPage";
import BillingPage from "./Pages/BillingPage";
import AffiliatePage from "./Pages/AffiliatePage";

import AboutUsPage from "./Pages/External/AboutUsPage";
import ContactUsPage from "./Pages/External/ContactUsPage";

import "./App.css";
import VerifyPage from "./Pages/VerifyPage";
import ForgottenPasswordPage from "./Pages/ForgottenPasswordPage";
import OverviewPage from "./Pages/External/OverviewPage";
import PricingPage from "./Pages/External/PricingPage";
import TermsPage from "./Pages/External/TermsPage";
import PrivacyPage from "./Pages/External/PrivacyPage";
import ChatPage from "./Pages/ChatPage";
import SubscriptionPage from "./Pages/Subscription/SubscriptionPage";
import Chat from "./Components/Chat/Chat";
import SponsorForm from "./Pages/Create/SponsorForm";

const token = getToken();

const ProtectedRoute = ({ token, redirectPath = "/login" }) => {
  const navigate = useNavigate();

  if (!verifyToken()) {
    // BOTH THESE CAUSE A RENDERING CRASH LOOK AT THIS AT SOME POINT
    //setTimeout(() => { navigate(redirectPath, { replace: true }) }, 1000);
    //return <Navigate to={redirectPath} replace={true}></Navigate>
    window.location = redirectPath;
    return;
  }



  return <>
    <Chat targetType="user" targetId="1" />
    <Outlet />
  </>;
};
/*
const ProtectedRoute = ({ token, redirectPath = "/login", children }) => {
  return children;
};
*/
function App() {
  return (
    <Provider store={store}>
      {/* <ColorModeScript initialColorMode={theme.config.initialColorMode} /> */}
      <ChakraProvider>
        <ContextProvider>
          <Router basename="/">
            <Routes>
              <Route path="/" element={<><LandingPage /></>} />
              <Route path="/sponsorForm" element={<><SponsorForm/></>}/>
              <Route path="/login" element={<><Login /></>} />
              <Route path="/signUp" element={<><SignUpPage /></>} />
              <Route path="/signUp/:affiliateCode" element={<><SignUpPage /></>} />
              <Route path="/subscription" element={<SubscriptionPage />} />
              <Route path="/onboard" element={<><OnboardingPage /></>} />
              <Route path="/about" element={<AboutUsPage />} />
              <Route path="/overview" element={<OverviewPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/contact" element={<ContactUsPage />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/verify/:token" element={<VerifyPage />} />
              <Route path="/forgotten/:token" element={<ForgottenPasswordPage />} />
              <Route path="/" element={<ProtectedRoute token={token} />}>
                <Route path="congratulation" element={<CongratulationForm />} />
                <Route path="goal" element={<GoalSettingPage />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="create" element={<CFirstPage />} />
                <Route path="create/content" element={<ContentPage />} />
                <Route path="create/content/card" element={<CardEditPage />} />
                <Route path="create/content/card/add" element={<CardCreatePage />} />
                <Route path="create/group" element={<GroupPage />} />
                <Route path="create/group/member" element={<MemberEditPage />} />
                <Route path="learn" element={<LFirstPage />} />
                <Route path="learn/content" element={<LContentPage />} />
                <Route path="learn/content/unit" element={<LUnitPage />} />
                <Route path="learn/content/unit/card" element={<LCardPage />} />
                <Route path="marketplace" element={<MainMarketPage />} />
                <Route path="marketplace/view" element={<ViewPage />} />
                <Route path="marketplace/bucket" element={<BucketPage />} />
                <Route path="marketplace/register" element={<RegisterPage />} />
                <Route path="marketplace/edit" element={<EditPage />} />
                <Route path="marketplace/preview" element={<PreviewPage />} />
                <Route path="marketplace/social" element={<SocialSharePage />} />
                <Route path="life" element={<MainLifePage />} />
                <Route path="life/view" element={<LViewPage />} />
                <Route path="life/bucket" element={<LBucketPage />} />
                <Route path="life/register" element={<LRegisterPage />} />
                <Route path="life/edit" element={<LEditPage />} />
                <Route path="life/preview" element={<LPreviewPage />} />
                <Route path="life/social" element={<LSocialSharePage />} />
                <Route path="academy/digital" element={<DigitalPassportPage />} />
                <Route path="academy/digital/:userHash" element={<DigitalPassportPage />} />
                <Route path="academy/leader" element={<LeaderBoardPage />} />
                <Route path="academy/job" element={<JobBoardPage />} />
                <Route path="academy/event" element={<EventBoardPage />} />
                <Route path="academy/job/create" element={<JobCreatePage />} />
                <Route path="academy/job/update" element={<JobUpdatePage />} />
                <Route path="academy/event/create" element={<EventCreatePage />} />
                <Route path="academy/event/update" element={<EventUpdatePage />} />
                <Route path="academy/job/view" element={<JobViewPage />} />
                <Route path="academy/event/view" element={<EventViewPage />} />
                <Route path="academy/job/edit" element={<JobEditPage />} />
                <Route path="setting" element={<SettingPage />} />
                <Route path="billing" element={<BillingPage />} />
                <Route path="affiliate" element={<AffiliatePage />} />
                <Route path="chat" element={<ChatPage />} />
              </Route>
            </Routes>
          </Router>
        </ContextProvider>
      </ChakraProvider>
    </Provider>
  );
}

export default App;
