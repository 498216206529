
// export default GoalSettingPage;
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Input, Button, Select, Text } from "@chakra-ui/react";

import { useStateContext } from "../ContextProvider/Context";
import { getToken, verifyToken } from "../utils";

import Navbar from "../Components/Navbar/Navbar";
import UniversalLeftSideBar from "../Components/Common/UniversalLeftSideBar";
import MainGoalForm from "../Components/Goal/MainGoalForm";

const GoalSettingPage = () => {
  const { G2_theme_mode, G2_color_1, G2_color_2, G2_color_3 } =
    useStateContext();

  const navigate = useNavigate();

  const user = useSelector((state) => state.Auth.user);
  const today = useSelector((state) => state.Auth.today);

  const [screenSize, setScreenSize] = useState(1366);
  const [leftBarShow, setLeftBarShow] = useState(true);
  const [boxW1, setBoxW1] = useState("50%");
  const [boxW2, setBoxW2] = useState("33.3%");

  const handleResize = () => {
    setScreenSize(window.innerWidth);

    if (window.innerWidth >= 1366) {
      setLeftBarShow(true);
      setBoxW1("50%");
      setBoxW2("33.3%");
    }
    if (window.innerWidth < 1366 && window.innerWidth >= 1024) {
      setLeftBarShow(true);
      setBoxW1("50%");
      setBoxW2("50%");
    }
    if (window.innerWidth < 1024 && window.innerWidth >= 768) {
      setLeftBarShow(true);
      setBoxW1("100%");
      setBoxW2("100%");
    }
    if (window.innerWidth < 768) {
      setLeftBarShow(false);
      setBoxW1("100%");
      setBoxW2("100%");
    }
  };

  const token = getToken();

  useEffect(() => {
    if (!verifyToken()) {
      navigate("/login");
    }

    const startDate = new Date(today);
    const endDate = new Date(user.expire);

    if (startDate > endDate) {
      navigate("/billing");
    }

    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  const leftBarClick = () => {
    setLeftBarShow(!leftBarShow);

    if (screenSize >= 1366) {
      setBoxW1("50%");
      setBoxW2("33.3%");
    }

    if (screenSize >= 1024 && screenSize < 1366 && leftBarShow == true) {
      setBoxW1("50%");
      setBoxW2("33.3%");
    }
    if (screenSize >= 1024 && screenSize < 1366 && leftBarShow == false) {
      setBoxW1("50%");
      setBoxW2("50%");
    }

    if (screenSize >= 768 && screenSize < 1024 && leftBarShow == true) {
      setBoxW1("100%");
      setBoxW2("50%");
    }
    if (screenSize >= 768 && screenSize < 1024 && leftBarShow == false) {
      setBoxW1("100%");
      setBoxW2("100%");
    }

    if (screenSize < 768) {
      setBoxW1("100%");
      setBoxW2("100%");
    }
  };
  return (
    <Box
      w="100%"
      minH="100vh"
      style={{ background: `${G2_color_1}`, color: `${G2_color_3}` }}
    >
      <Box w="100%">
        <Navbar title="Goal" />
      </Box>
      <Box w="100%" display="flex">
        {leftBarShow && (
          <Box className="universal-leftBar-w">
          <Box w="100%" h="100vh" className="sticky top-0">
            <UniversalLeftSideBar />
          </Box>
        </Box>
        )}
        <Box flex="1" p="4">
          <Box display="flex" justifyContent="space-between" alignItems="center" mb="4">
            <Input placeholder="Search here..." w="60%" mr="4" />
            <Select w="20%" mr="4">
              <option value="all">All</option>
            </Select>
            <Button colorScheme="orange" w="20%">
              Add Goal
            </Button>
          </Box>
          <Box>
            <Text fontSize="lg" mb="4">
              Your Goals
            </Text>
            <Box
              w="100%"
              h="auto"
              bg="white"
              boxShadow="md"
              p="4"
              borderRadius="md"
            >
              <MainGoalForm screenSize={screenSize} boxW1={boxW1} boxW2={boxW2} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GoalSettingPage;
